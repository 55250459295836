import {Injectable} from '@angular/core';

import {Message} from '../../helpers/messages';

import * as $ from 'jquery';

const message = new Message();

@Injectable()

export class ScrollService {

    constructor() {

    }

    scrollToFirstOfClass() {
        setTimeout(() => {
            let el = $('.ng-invalid > input').first();
            if (el.length === 0) {
                el = $('.ng-invalid > textarea').first();
            }
            if (el.length > 0) {
                $('html,body').animate({scrollTop: (el.offset().top)}, 'slow', () => {
                    el.focus();
                });
            }
        }, 0);
    }
}
