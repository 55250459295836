import {Injectable} from '@angular/core';
import {tap} from 'rxjs/operators';
import config from '../../configs';
import {HttpService} from '../http/http.service';
import UserModel from '../user/user.model';
import {UserService} from '../user/user.service';
import {Router} from '@angular/router';
import Cookies from 'js-cookie';

export interface ILoginResponse {
    token: string;
    user: UserModel;
}

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    static AUTH_TOKEN = 'AUTH_TOKEN';

    constructor(
        private httpService: HttpService,
        private userService: UserService,
        private router: Router
    ) {
    }

    login(username: string, password: string) {
        return this.httpService.post<ILoginResponse>(`${config.accountHost}/users/login/`, {
            username,
            password
        }, 
        )
            .pipe(tap((data: any) => {
                localStorage.setItem(AuthService.AUTH_TOKEN, data.token);
                localStorage.setItem('user', JSON.stringify(data.user));
            }));
    }

    logout() {
        localStorage.clear();
        this.userService.user = null;
        this.router.navigate(['/login']);
    }

}
