import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {IonContent, ToastController} from '@ionic/angular';
import {PipeCommunicationService} from '../../../services/communication/pipe-communication.service';
import {DrillingService} from '../../../services/drilling/drilling.service';
import {UtilityService} from '../../../services/utility/utility.service';
import {ConstructionSiteService} from '../../../services/construction-site/construction-site.service';
import {Router} from '@angular/router';
import {FormsService} from '../../../services/form/forms.service';

@Component({
    selector: 'app-drilling-details',
    templateUrl: './drilling-details.page.html',
    styleUrls: ['./drilling-details.page.scss'],
})
export class DrillingDetailsPage implements OnInit {
    @Input() pipeDetails = {};
    public isLastPipeInserted: boolean;
    public constructionSiteId: number;
    public reportId: number;
    public drillingId: number;
    public pipeIndex: number;
    public lastPipeInList = false;
    public siteType: string;

    isLastPipeChecked = false;

    @ViewChild(IonContent, {static: true}) ionContent: IonContent;

    public pipeForm = new FormGroup({
        id: new FormControl('', [Validators.required]),
        depth: new FormControl('', [Validators.required]),
        direction: new FormControl('', [Validators.required]),
        slope: new FormControl('', [Validators.required]),
        pipe_diameter: new FormControl(),
        geology: new FormControl('', [Validators.required]),
        purgeVolume: new FormControl('', [Validators.required]),
        color: new FormControl('', [Validators.required]),
        drillType: new FormControl('', [Validators.required]),
        moisture: new FormControl('', [Validators.required]),
        comment: new FormControl(''),
        time: new FormControl('', [Validators.required]),
        isLastPipe: new FormControl(false, [Validators.required]),
        totalLength: new FormControl('', [Validators.required]),
    });

    public mapFieldToColumns = {
        time: 'time',
        depth: 'depth',
        direction: 'direction',
        slope: 'slope',
        pipe_diameter: 'pipe_diameter',
        geology: 'geology',
        purge_volume: 'purgeVolume',
        color: 'color',
        drill_type: 'drillType',
        moisture: 'moisture',
        comment: 'comment',
        is_last_pipe: 'isLastPipe',
        total_length: 'totalLength'
    };

    constructor(
        private utilityService: UtilityService,
        private drillingService: DrillingService,
        private activatedRoute: ActivatedRoute,
        private pipeCommunicationService: PipeCommunicationService,
        private constructionSiteService: ConstructionSiteService,
        private toastController: ToastController,
        private router: Router,
        private forms: FormsService
    ) {
    }

    ngOnInit() {
        this.constructionSiteId = this.utilityService.getAllURLParams(this.activatedRoute.snapshot.pathFromRoot).constructionSiteId;
        this.reportId = this.utilityService.getAllURLParams(this.activatedRoute.snapshot.pathFromRoot).reportId;
        this.drillingId = this.utilityService.getAllURLParams(this.activatedRoute.snapshot.pathFromRoot).drillingId;
        this.pipeIndex = Number(this.utilityService.getAllURLParams(this.activatedRoute.snapshot.pathFromRoot).pipeIndex);
        
        //we assume there is one report on any construction site
        this.getPipeData();

        const totalLength = this.pipeForm.get('totalLength');

        this.pipeForm.get('isLastPipe').valueChanges
            .subscribe(isLastPipe => {
                if (isLastPipe) {
                    totalLength.setValidators([Validators.required]);
                } else {
                    totalLength.setValidators([]);
                }
            });
        
        this.siteType = this.constructionSiteService.currentSite.site_type;
        if(this.siteType !== 'f') {
            this.pipeForm.get('direction').clearValidators();
            this.pipeForm.get('direction').updateValueAndValidity();
            this.pipeForm.get('slope').clearValidators();
            this.pipeForm.get('slope').updateValueAndValidity();
            this.pipeForm.get('geology').clearValidators();
            this.pipeForm.get('geology').updateValueAndValidity();
            this.pipeForm.get('purgeVolume').clearValidators();
            this.pipeForm.get('purgeVolume').updateValueAndValidity();
            this.pipeForm.get('time').clearValidators();
            this.pipeForm.get('time').updateValueAndValidity();
        }
        if(this.siteType !== 'e') {
            this.pipeForm.get('color').clearValidators();
            this.pipeForm.get('color').updateValueAndValidity();
            this.pipeForm.get('drillType').clearValidators();
            this.pipeForm.get('drillType').updateValueAndValidity();
            this.pipeForm.get('moisture').clearValidators();
            this.pipeForm.get('moisture').updateValueAndValidity();
        }
    }

    getPipeData() {
        this.drillingService.getOne(this.constructionSiteId, this.reportId, this.drillingId).subscribe(drilling => {
            this.pipeForm.setValue(drilling);
            this.isLastPipeInserted = drilling.isLastPipe;
            this.isLastPipe(drilling.id);
        });
    }

    isLastPipe(drillingId) {
        this.drillingService.getAll(this.constructionSiteId, this.reportId).subscribe(drillings => {
            drillings.sort((drilling1, drilling2) => (drilling1.id > drilling2.id) ? 1 : -1);
            drillings.forEach((drilling, index, array) => {
                if (index === (array.length - 1)) {
                    if (drilling.id === drillingId) {
                        this.lastPipeInList = true;
                    }
                }
            });
        });
    }

    clearTotalLength(checkedValue) {
        if (!checkedValue) {
            this.pipeForm.controls.totalLength.patchValue(null);
        }
    }

    onEdit(event, form) {

        if (!this.forms.defaultValidate(event, form)) {
            return false;
        }

        const pipeData = Object.assign({}, this.pipeForm.value);

        const pipeToSave = {
            time: parseInt(pipeData.time, 10),
            depth: pipeData.depth,
            direction: pipeData.direction,
            slope: pipeData.slope,
            pipe_diameter: pipeData.pipe_diameter,
            geology: pipeData.geology,
            purge_volume: pipeData.purgeVolume,
            color: pipeData.color,
            drill_type: pipeData.drillType,
            moisture: pipeData.moisture,
            comment: pipeData.comment,
            is_last_pipe: pipeData.isLastPipe,
            total_length: pipeData.totalLength,
            construction_site: this.constructionSiteId,
            drilling_report: this.reportId,
        };

        this.drillingService.updatePipe(this.constructionSiteId, this.reportId, this.drillingId, pipeToSave as any)
            .subscribe(
                async (drilling) => {
                    await this.getPipeData();
                    await this.pipeCommunicationService.emitUpdateDrillingPipeData();
                    await this.presentSuccessToast();
                    this.router.navigate(['/construction-sites/' + this.constructionSiteId + '/drilling/' + this.reportId]);
                },
                error => {
                    this.forms.defaultEndProcess(form, error, {skip_success: true}, this.mapFieldToColumns);
                }, () => {
                    this.forms.defaultEndProcess(form, {}, {skip_success: true});
                });
    }

    onRedirectBack() {
        this.router.navigate(['/construction-sites/' + this.constructionSiteId + '/drilling/' + this.reportId]);
    }

    private async presentSuccessToast() {
        const toast = await this.toastController.create({
            message: 'Your changes have been saved.',
            duration: 2000,
        });
        await toast.present();
    }
}
