import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class PipeCommunicationService {

    private emitUpdateWeldNumber = new Subject<any>();
    updateWeldEmitted$ = this.emitUpdateWeldNumber.asObservable();
    private emitUpdateDrillingPipe = new Subject<any>();
    updateDrillingPipeEmitted$ = this.emitUpdateDrillingPipe.asObservable();

    constructor() {
    }

    emitUpdateWelds() {
        this.emitUpdateWeldNumber.next();
    }

    emitUpdateDrillingPipeData() {
        this.emitUpdateDrillingPipe.next();
    }
}
