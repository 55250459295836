export default class DrillingReportModel {
    public id: number;
    public date: string;
    public name: string

    constructor(props) {
        this.id = props.id;
        this.date = props.date;
        this.name = props.name;
    }
}
