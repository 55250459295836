import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Observable} from 'rxjs';
import ConstructionSiteModel from '../services/construction-site/construction-site.model';
import {ConstructionSiteService} from '../services/construction-site/construction-site.service';
import {CurrentPathService} from '../services/current-path/current-path.service';
import {DailyReportModel} from '../services/daily-report/daily-report.model';
import {DailyReportService} from '../services/daily-report/daily-report.service';

@Component({
    selector: 'app-construction-site',
    templateUrl: './construction-site.page.html',
    styleUrls: ['./construction-site.page.scss']
})
export class ConstructionSitePage implements OnInit {
    static TABS_ARRAY = [
        'overview',
        'welding',
        'drilling',
        'testing',
        'daily-report',
        'dimension-report'
    ];
    public constructionSiteData: Observable<ConstructionSiteModel>;
    public isBarVisible = true;
    public dailyReports$: Observable<Array<DailyReportModel>>;
    public countDailyReport: any;
    public siteType: string;

    constructor(
        private currentPathService: CurrentPathService,
        private dailyReportServiceService: DailyReportService,
        private constructionSiteService: ConstructionSiteService,
        private route: ActivatedRoute
    ) {
    }

    ngOnInit() {
        const id = parseInt(
            this.route.snapshot.paramMap.get('constructionSiteId'),
            10
        );
        this.currentPathService.currentPathState.subscribe(
            value =>
                (this.isBarVisible = ConstructionSitePage.TABS_ARRAY.includes(value))
        );

        this.constructionSiteData = this.constructionSiteService.getOne(id);
        this.constructionSiteData.subscribe(respData => {
            this.siteType = respData.site_type;
        });

        this.dailyReports$ = this.dailyReportServiceService.getAll(id);
        this.dailyReports$.subscribe(respData => {
            this.countDailyReport = respData.length;
        });
    }
}
