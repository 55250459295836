<ion-card>
    <ion-card-content>
        <ion-row>
            <ion-thumbnail *ngIf="constructionData.image" class="thumbnail">
                <img [src]="constructionData.image">
            </ion-thumbnail>

            <ion-col class="construction-details">
                <ion-row>
                    <h2>
                        <ion-text>
                            {{constructionData.title}}
                        </ion-text>
                    </h2>
                </ion-row>
                <ion-row>
                    <h3>
                        <ion-label>Baustellenchef: </ion-label> 
                        <ion-text>{{constructionData.projectManager.firstName}} {{constructionData.projectManager.lastName}}</ion-text>
                    </h3>
                </ion-row>
                <ion-row>
                    <h3>
                        <ion-label>Baustellen Nr: </ion-label>
                        <ion-text>{{constructionData.siteNumber}}</ion-text>
                    </h3>
                </ion-row>
                <ion-row>
                    <h3>
                        <ion-label>Projektleiter: </ion-label>
                        <ion-text>{{constructionData.principal.firstName}} {{constructionData.principal.lastName}}</ion-text>
                    </h3>
                </ion-row>
            </ion-col>
        </ion-row>
    </ion-card-content>
</ion-card>
