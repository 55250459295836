<ion-content #content>
    <ion-row class="pipes">
        <ion-col *ngFor="let pipe of pipes; let index = index; last as isLast;" sizeMd="6" sizeXs="12">
            <app-pipe-card
                    [constructionSiteId]="constructionSiteId"
                    [data]="pipe"
                    [isLastPipe]="isLast"
                    [isWelding]="true"
                    [pipeIndex]="index"></app-pipe-card>
        </ion-col>
    </ion-row>
</ion-content>
