import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';
import {HeaderModule} from '../shared/header/header.module';

import {MainLayoutPage} from './main-layout.page';

const routes: Routes = [
    {
        path: '',
        component: MainLayoutPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        HeaderModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        HeaderModule
    ],
    declarations: [MainLayoutPage]
})
export class MainLayoutPageModule {
}
