import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {HeaderModule} from '../header/header.module';
import {AddWeldingModalComponent} from './add-welding-modal.component';


@NgModule({
    entryComponents: [AddWeldingModalComponent],
    exports: [AddWeldingModalComponent],
    declarations: [AddWeldingModalComponent],
    imports: [
        CommonModule,
        IonicModule,
        HeaderModule,
        ReactiveFormsModule
    ]
})
export class AddWeldingModalModule {
}
