import {MachineReportModel} from '../shared-models/machine-report.model';
import {MaterialReportModel} from '../shared-models/material-report.model';
import {WeatherModel} from '../shared-models/weather.model';
import {WorkerReportModel} from '../shared-models/worker-report.model';
import UserModel from '../user/user.model';

export class DailyReportModel {
    public id: number;
    public date: string;
    public reporter: UserModel;
    public temperature: number;
    public weather: Array<WeatherModel>;
    public weatherNote: string;
    public workersReports?: Array<WorkerReportModel>;
    public machinesReports?: Array<MachineReportModel>;
    public materialReports?: Array<MaterialReportModel>;
    public constructionId: number;

    constructor(props) {
        this.id = props.id;
        this.date = props.date;
        this.reporter = new UserModel(props.reporter);
        this.temperature = props.temperature;
        this.weather = props.weather.map(weather => new WeatherModel(weather));
        this.weatherNote = props.weather_note;
        // tslint:disable-next-line:max-line-length
        this.workersReports = props.worker_reports ? props.worker_reports.map(worker => new WorkerReportModel(worker)) : [];
        this.machinesReports = props.machine_reports ? props.machine_reports.map(machine => new MachineReportModel(machine)) : [];
        this.materialReports = props.material_reports ? props.material_reports.map(material => new MaterialReportModel(material)) : [];
        this.constructionId = props.construction_site;
    }
}

export class DailyReportPostData {

}

export interface IDailyReportResponse {
    id: number;
}
