import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {HeaderService} from '../services/header/header.service';

@Component({
    selector: 'app-main-layout',
    templateUrl: './main-layout.page.html',
    styleUrls: ['./main-layout.page.scss'],
})
export class MainLayoutPage implements OnInit {

    constructor(public headerService: HeaderService, private router: Router) {
    }

    ngOnInit() {
    }

}
