import {Injectable} from '@angular/core';
import {Message} from '../../helpers/messages';

const message = new Message();
import {ScrollService} from '../scroll/scroll.service';

@Injectable()

export class FormsService {
    constructor(private scroll: ScrollService) {

    }

    defaultValidate(event, form) {

        form.markAllAsTouched();

        if (event) {
            event.preventDefault();
            event.stopPropagation();
        }
        form['$submitted'] = true;
        form['$error_messages'] = false;
        form['$success_message'] = false;
        if (!form.valid || form['$submitting']) {
            form['$submitting'] = false;
            this.scroll.scrollToFirstOfClass();
            return false;
        }
        form['$submitting'] = true;
        return true;
    }

    defaultEndProcess(form, result: any, skip_messages = {}, fieldMapping = {}) {
        form['$submitted'] = false;
        form['$submitting'] = false;
        form.markAsPristine();
        form.markAsUntouched();
        if (!result) {
            form['$error_messages'] = ['Something went wrong! Please try again later.'];
        }
        if (result.error || result.errors) {
            message.log('Form error messages', result);

            let errorFields = Object.keys(result.error);

            errorFields.forEach((error) => {

                if (fieldMapping && fieldMapping[error]) {
                    error = fieldMapping[error];
                }

                if (error && form.controls[error]) {
                    const control = form['controls'][error];
                    const control_error = {};
                    control_error['invalid'] = true;
                    control.setErrors(control_error);
                    control.markAsTouched();
                }
            });

            if (errorFields.includes("non_field_errors")) {
                form['$error_messages'] = result.error['non_field_errors'][0];
            }

            this.scroll.scrollToFirstOfClass();
            message.log(form);
            return false;
        }
        return (!skip_messages['skip_success']) ? (form['$success_message']) = result : true;
    }
}
