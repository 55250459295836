import {Component, Input, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {AuthService} from '../../services/auth/auth.service';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
    @Input() isInModal = false;
    
    public user: String

    constructor(
        private modalController: ModalController,
        private authService: AuthService
    ) {
    }

    ngOnInit() {
        const user = JSON.parse(localStorage.getItem('user'))
        this.user = user && (user.first_name + " " + user.last_name);
    }

    public closeModal(): void {
        this.modalController.dismiss();
    }

    public logout() {
        this.authService.logout();
    }

}
