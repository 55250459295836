<ion-header>
    <ion-toolbar class="background-color">
        <ion-buttons *ngIf="isInModal" slot="start">
            <ion-button (click)="closeModal()">
                <ion-icon name="close"></ion-icon>
            </ion-button>
        </ion-buttons>
        <a routerLink="/construction-sites">
            <ion-img src="/static/assets/icon/stemba_logo_white.png"></ion-img>
        </a>
        <ion-buttons slot="end">
            <ion-text class="ion-text-start ion-text-nowrap">{{user}}</ion-text>
            <ion-button (click)="logout()">
                <ion-label i18n>Logout</ion-label>
            </ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>

