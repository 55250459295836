import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {HeaderModule} from '../header/header.module';
import {AddPipeModalComponent} from './add-pipe-modal.component';


@NgModule({
    entryComponents: [
        AddPipeModalComponent
    ],
    declarations: [
        AddPipeModalComponent
    ],
    exports: [
        AddPipeModalComponent
    ],
    imports: [
        HeaderModule,
        IonicModule,
        CommonModule,
        ReactiveFormsModule
    ]
})
export class AddPipeModalModule {
}
