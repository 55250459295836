import {ActivatedRouteSnapshot, CanActivateChild, RouterStateSnapshot} from '@angular/router';
import {ModalController} from '@ionic/angular';
import {Observable} from 'rxjs';

export class DismissAllGuard implements CanActivateChild {
    constructor(
        private modalController: ModalController,
    ) {
    }

    canActivateChild(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean> | Promise<boolean> | boolean {
        return this.checkForOpenModals()
            .then(() => {
                return true;
            })
            .catch(() => {
                return false;
            });
    }

    async checkForOpenModals() {
        try {
            const element = await this.modalController.getTop();
            if (element) {
                element.dismiss();
                return;
            }
        } catch (error) {
            console.log(error);

        }
    }
}