<ion-tabs>
    <ion-tab-bar *ngIf="siteType" slot="top" class="tab-container" scrollX="true">

        <ion-tab-button class="tab" tab="overview">
            <ion-label>Übersicht</ion-label>
        </ion-tab-button>

        <ion-tab-button *ngIf="siteType === 'f'" class="tab" tab="welding">
            <ion-label>Schweissprotokoll</ion-label>
        </ion-tab-button>

        <ion-tab-button *ngIf="siteType !== 'h'" class="tab" tab="drilling">
            <ion-label>Bohrprotokoll</ion-label>
        </ion-tab-button>

        <ion-tab-button *ngIf="siteType === 'e'" class="tab" tab="testing">
            <ion-label>Prüfprotokoll</ion-label>
        </ion-tab-button>

        <ion-tab-button class="tab" tab="daily-report">
            <ion-label>Tagesrapport</ion-label>
        </ion-tab-button>

        <ion-tab-button class="tab" tab="dimension-report">
            <ion-label>Ausmassblatt</ion-label>
        </ion-tab-button>

    </ion-tab-bar>
</ion-tabs>

<!--<router-outlet *ngIf="!isBarVisible"></router-outlet>-->
