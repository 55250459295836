<ion-content>
    <ion-row class="intro-row">
        <ion-col class="intro-left">
            <h3 class="ic-title">Willkommen</h3>
            <ion-text class="ic-text">
                Eine Übersicht der zugeteilten Baustellen.
            </ion-text>
        </ion-col>
        <ion-col sizeXs="12" sizeSm="6" sizeMd="4" sizeLg="3">
            <ion-button class="time-tracking-btn" expand="full" (click)="showTimeTrackingModal()">
                <ion-label i18n>Zeiterfassung</ion-label>
            </ion-button>
        </ion-col>
    </ion-row>
    <ion-row class="ion-justify-content-center">
        <ion-segment class="segment" mode="md" color="dark" [(ngModel)]="siteType" (ionChange)="siteTypeChanged($event.detail.value)" value="f">
            <ion-segment-button value="f">
                <ion-label>Horizontalbohrung</ion-label>
            </ion-segment-button>
            <ion-segment-button value="e">
                <ion-label>Erdsonde</ion-label>
            </ion-segment-button>
            <ion-segment-button value="h">
                <ion-label>Hausanschluss</ion-label>
            </ion-segment-button>
        </ion-segment>
    </ion-row>
    <ion-row>
        <ion-col *ngFor="let constructionSite of constructionSitesFiltered | async" class="construction-sites" sizeLg="6"
                 sizeMd="12" sizeXs="12">
            <a routerLink="{{constructionSite.id}}">
                <app-construction-site-card [constructionData]="constructionSite"></app-construction-site-card>
            </a>
        </ion-col>
    </ion-row>
</ion-content>
