import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import config from '../../configs';
import {HttpService} from '../http/http.service';
import DrillingReportModel from './drilling-report.model';
import Cookies from 'js-cookie';

@Injectable({
    providedIn: 'root'
})
export class DrillingReportService {

    constructor(private httpService: HttpService) {
    }

    public getAll(constructionId: number) {
        return this.httpService.get<Array<DrillingReportModel>>(`${config.apiHost}/construction-sites/${constructionId}/drilling-reports/`)
            .pipe(map(pipes => pipes.map(pipe => new DrillingReportModel(pipe))));
    }

    public create(constructionId: number) {
        const endpoint = `${config.apiHost}/construction-sites/${constructionId}/drilling-reports/`;
        return this.httpService.post<DrillingReportModel>(endpoint, {construction_site: constructionId});
    }
}
