import {UtilityService} from '../utility/utility.service';

export class WorkEntryModel {
    public id: number;
    public context: string;
    public howLong: string;
    public workReportId: number;

    constructor(props) {
        this.id = props.id;
        this.context = props.context;
        this.howLong = props.how_long;
        this.workReportId = props.work_report;
    }
}
