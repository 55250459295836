<script src="add-pipe-modal.component.ts"></script>
<app-header [isInModal]="true"></app-header>
<ion-content>
    <form (ngSubmit)="onSave($event,pipeForm)" [formGroup]="pipeForm">

        <ion-row class="ion-margin">
            <ion-col class="input" sizeMd="3" sizeXs="12">
                <ion-label position="floating" i18n>Tiefe (m)</ion-label>
                <ion-input formControlName="depth" inputmode="decimal" type="number"></ion-input>
            </ion-col>
            <ion-col *ngIf="siteType === 'f'" class="input" sizeMd="3" sizeXs="12">
                <ion-label position="floating" i18n>Steigung (%)</ion-label>
                <ion-input formControlName="slope" inputmode="decimal" type="number"></ion-input>
            </ion-col>
            <ion-col *ngIf="siteType === 'f'" class="input" sizeMd="3" sizeXs="12">
                <ion-label position="floating" i18n>Richtung (°)</ion-label>
                <ion-input formControlName="direction" inputmode="decimal" type="number"></ion-input>
            </ion-col>
            <ion-col *ngIf="siteType === 'e'" class="input" sizeMd="3" sizeXs="12">
                <ion-label position="floating" i18n>Farbe</ion-label>
                <ion-input formControlName="color" type="text"></ion-input>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="siteType === 'f'" class="ion-margin">

            <ion-col class="input" sizeMd="12" sizeXs="12">
                <ion-label [class.ion-invalid]="pipeForm.controls['geology'].invalid && formSubmitted">Geologie
                </ion-label>
            </ion-col>

            <ion-col class="input ion-checkbox-group" sizeSm="5" sizeMd="5" sizeXl="3"
                     *ngFor="let geology of geologyTypes">

                <ion-item>
                    <ion-label>{{geology.name}}</ion-label>
                    <ion-checkbox slot="start" [value]="geology.name"
                                  (ionChange)="onGeologyChanged($event)"></ion-checkbox>
                </ion-item>

            </ion-col>
        </ion-row>

        <ion-row *ngIf="siteType === 'f'" class="ion-margin">
            <ion-col class="input" sizeMd="3" sizeXs="5">
                <ion-label position="floating" i18n>Zeit (Minuten)</ion-label>
                <ion-input formControlName="time" type="number" placeholder=""></ion-input>
            </ion-col>
            <ion-col class="input" sizeMd="3" sizeXs="12">
                <ion-label position="floating" i18n>Spülvolumen (l)</ion-label>
                <ion-input formControlName="purgeVolume" type="number"></ion-input>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="siteType === 'e'" class="ion-margin">
            <ion-col class="input" sizeMd="4" sizeXs="12">
                <ion-label position="stacked" i18n>Bohrertyp</ion-label>
                <ion-select class="select" formControlName="drillType" multiple="false">
                    <ion-select-option value="f">
                        Spülbohrung
                    </ion-select-option>
                    <ion-select-option value="h">
                        Hammerbohrung
                    </ion-select-option>
                    <ion-select-option value="p">
                        PDC/Luft
                    </ion-select-option>
                </ion-select>
            </ion-col>
            <ion-col class="input" sizeMd="4" sizeXs="12">
                <ion-label position="stacked" i18n>Wassergehalt</ion-label>
                <ion-select class="select" formControlName="moisture" multiple="false">
                    <ion-select-option value="w">
                        Nass
                    </ion-select-option>
                    <ion-select-option value="m">
                        Feucht
                    </ion-select-option>
                    <ion-select-option value="d">
                        Trocken
                    </ion-select-option>
                </ion-select>
            </ion-col>
            <ion-col class="input" sizeMd="4" sizeXs="12">
                <ion-label i18n>Verrohrung Durchmesser</ion-label>
                <ion-input formControlName="pipe_diameter"></ion-input>
            </ion-col>
        </ion-row>

        <ion-row class="ion-margin">
            <ion-col class="input" sizeMd="6" sizeXs="12">
                <ion-label position="floating" i18n>Bemerkung</ion-label>
                <ion-textarea formControlName="comment" rows="6"></ion-textarea>
            </ion-col>
        </ion-row>

        <ion-row class="ion-margin">
            <ion-col sizeMd="5">
                <ion-checkbox [(ngModel)]="isLastPipeChecked" checked="false" color="dark"
                              formControlName="isLastPipe"></ion-checkbox>
                <ion-label i18n>Letzte Strange</ion-label>
            </ion-col>
        </ion-row>

        <ion-row class="ion-margin" *ngIf="isLastPipeChecked">
            <ion-col class="input" sizeMd="3" sizeXs="5">
                <ion-label position="floating" i18n>Gesamtlänge (m)</ion-label>
                <ion-input formControlName="totalLength" inputmode="decimal"
                           type="number"></ion-input>
            </ion-col>
        </ion-row>

        <ion-row class="ion-margin">
            <ion-col sizeLg="3" sizeMd="4" sizeXs="6">
                <ion-label class="ion-invalid" *ngIf="pipeForm['$error_messages']">
                    {{pipeForm['$error_messages']}}
                </ion-label>
            </ion-col>
        </ion-row>

        <ion-row align-items-center class="button-row ion-justify-content-center">
            <ion-col sizeXs="4" text-center>
                <ion-button color="dark" expand="full" type="submit"
                    class="ion-text-uppercase" [disabled]="pipeForm['$submitting']" type="submit">
                    <ion-spinner *ngIf="pipeForm['$submitting']"></ion-spinner>
                    <ion-label *ngIf="!pipeForm['$submitting']" i18n>Speichern</ion-label>
                </ion-button>

            </ion-col>
        </ion-row>
    </form>
</ion-content>
