import {Injectable, OnDestroy} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {BehaviorSubject, Subscription} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CurrentPathService implements OnDestroy {

    public currentPathState = new BehaviorSubject('');
    public mainSub: Subscription;

    constructor(private router: Router) {
        this.mainSub = this.router.events.subscribe(value => {
            if (value instanceof NavigationEnd) {
                const paths = value.urlAfterRedirects.split('/');
                const recentPaths = paths.reverse().slice(0, 2);
                if (isNaN(recentPaths[0] as any)) {
                    return this.currentPathState.next(recentPaths[0]);
                }
                this.currentPathState.next(recentPaths[1]);
            }
        });
    }

    ngOnDestroy() {
        this.mainSub.unsubscribe();
    }
}
