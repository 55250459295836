export default class PipeModel {
    public id: number;
    public depth: number;
    public direction: string;
    public slope: number;
    public pipe_diameter: number;
    public geology: string;
    public purgeVolume: string;
    public color: string;
    public drillType: string;
    public moisture: string;
    public comment: string;
    public time: string;
    public isLastPipe: boolean;
    public totalLength: number;

    constructor(props: any | PipeModel) {
        this.id = props.id;
        this.depth = props.depth;
        this.direction = props.direction;
        this.slope = props.slope;
        this.pipe_diameter = props.pipe_diameter;
        this.geology = props.geology;
        this.purgeVolume = props.purge_volume;
        this.color = props.color;
        this.drillType = props.drill_type;
        this.moisture = props.moisture;
        this.comment = props.comment;
        this.time = props.time;
        
        const isConstructedFromJson = props.isLastPipe === undefined;
        if (isConstructedFromJson) {
            this.isLastPipe = props.is_last_pipe;
            this.totalLength = props.total_length;
        } else {
            this.isLastPipe = props.isLastPipe;
            this.totalLength = props.totalLength;
        }
    }
}
