export class MachineModel {
    public id: number;
    public name: string;
    public isDrillingMachine = false;

    constructor(props) {
        this.id = props.id;
        this.name = props.name;
        this.isDrillingMachine = props.is_drilling_machine;
    }
}
