<ion-card>
    <ion-card-content>
        <ion-row>
            <ion-col>
                <h5 *ngIf="isWelding">
                    {{data.name}}
                </h5>
                <h5 *ngIf="!isWelding && !isEarthProbe">
                    Bohrstange {{pipeIndex + 1}}
                </h5>
                <h5 *ngIf="!isWelding && isEarthProbe">
                    Geologie {{pipeIndex + 1}}
                </h5>
            </ion-col>
        </ion-row>

        <ion-row class="ion-align-items-center">
            <ion-col sizeMd="4" *ngIf="isWelding">
                Länge: {{data.length}} mm
            </ion-col>
        </ion-row>

        <ion-row *ngIf="isWelding" class="ion-align-items-center">
            <ion-col sizeMd="4">
                Anzahl: {{weldsNumber}}
            </ion-col>
        </ion-row>

        <ion-row *ngIf="!isWelding && !isEarthProbe" class="ion-align-items-center">
            <ion-col *ngIf="data.totalLength && isLastPipe" sizeMd="3">
                {{data.totalLength}}m
            </ion-col>
            <ion-col sizeMd="3">
                Tiefe: {{data.depth}}m
            </ion-col>
            <ion-col sizeMd="3">
                Richtung: {{data.direction}}°
            </ion-col>
            <ion-col sizeMd="3">
                Steigung: {{data.slope}}%
            </ion-col>
        </ion-row>

        <ion-row *ngIf="!isWelding && isEarthProbe" class="ion-align-items-center">
            <ion-col *ngIf="data.totalLength && isLastPipe" sizeMd="3">
                {{data.totalLength}}m
            </ion-col>
            <ion-col sizeMd="3">
                Tiefe: {{data.depth}}m
            </ion-col>
        </ion-row>

        <ion-row *ngIf="isWelding" class="ion-align-items-center">
            <ion-col sizeMd="6">
                <ion-button class="link" no-padding routerLink="{{data.id}}">SCHWEISSNÄHTE ANZEIGEN</ion-button>
            </ion-col>
        </ion-row>

        <ion-row *ngIf="!isWelding">
            <ion-col text-left>
                <ion-button class="link" no-padding routerLink="{{data.id}}/{{pipeIndex}}">
                    DETAILS ANZEIGEN
                </ion-button>
            </ion-col>
        </ion-row>

    </ion-card-content>
</ion-card>
