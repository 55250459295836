import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import GeologyModel from './geology.model';
import config from '../../configs';
import {HttpService} from '../http/http.service';
import DrillingModel from './drilling.model';
import Cookies from 'js-cookie';

@Injectable({
    providedIn: 'root'
})
export class DrillingService {

    constructor(private httpService: HttpService) {
    }

    public getOne(constructionId: number, reportId: number, drillingId: number) {
        return this.httpService.get<DrillingModel>(`${config.apiHost}/construction-sites/${constructionId}/drilling-reports/${reportId}/drillings/${drillingId}`)
            .pipe(
                map((drilling) => new DrillingModel(drilling))
            );
    }

    public getGeologyTypes() {
        return this.httpService.get<Array<GeologyModel>>(`${config.apiHost}/drilling-geology/`)
            .pipe(map(drilling => drilling.map(drilling => new GeologyModel(drilling))));
    }

    public getAll(constructionId: number, reportId: number) {
        return this.httpService.get<Array<DrillingModel>>(`${config.apiHost}/construction-sites/${constructionId}/drilling-reports/${reportId}/drillings`)
            .pipe(map(drilling => drilling.map(drilling => new DrillingModel(drilling))));
    }

    public delete(constructionId: number, reportId: number, drillingId: number) {
        // tslint:disable-next-line:max-line-length
        return this.httpService.delete<Array<DrillingModel>>(`${config.apiHost}/construction-sites/${constructionId}/drilling-reports/${reportId}/drillings/${drillingId}`);
    }

    public save(constructionId: number, reportId: number, body: DrillingModel) {
        return this.httpService.post<DrillingModel>(`${config.apiHost}/construction-sites/${constructionId}/drilling-reports/${reportId}/drillings/`, body)
            .pipe(
                map((drilling: DrillingModel) => new DrillingModel(drilling))
            );
    }

    public updatePipe(constructionId: number, reportId: number, drillingId: number, body: DrillingModel) {
        // tslint:disable-next-line:max-line-length
        return this.httpService.put<DrillingModel>(`${config.apiHost}/construction-sites/${constructionId}/drilling-reports/${reportId}/drillings/${drillingId}/`, body)
            .pipe(
                map((drilling: DrillingModel) => new DrillingModel(drilling))
            );
    }
}
