<app-header isInModal="true"></app-header>

<ion-content>
    <form (ngSubmit)="saveWeld($event, weldForm)" [formGroup]="weldForm">
        <ion-row>
            <ion-col class="title" sizeMd="12" sizeXs="12">
                <h4>Basisinformation</h4>
            </ion-col>
        </ion-row>

        <ion-row class="ion-margin">
            <ion-col class="input" size-md="6">
                <ion-label position="stacked">Datum</ion-label>
                <ion-datetime displayFormat="DD.MM.YYYY" formControlName="date"></ion-datetime>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col class="title" sizeMd="12" sizeXs="12">
                <h4>Temperatur</h4>
            </ion-col>
        </ion-row>

        <ion-row class="ion-margin">
            <ion-col class="input">
                <ion-label position="stacked">Temp (°C)</ion-label>
                <ion-input formControlName="temperature"
                           inputmode="decimal"
                           type="number"></ion-input>
            </ion-col>
            <ion-col class="input">
                <ion-label position="stacked">Heizelement Temp. (°C)</ion-label>
                <ion-input formControlName="heatingElementTemperature"
                           inputmode="decimal"
                           type="number"></ion-input>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col class="title" sizeMd="12" sizeXs="12">
                <h4>Bewegungsdruck</h4>
            </ion-col>
        </ion-row>

        <ion-row class="ion-margin">
            <ion-col class="input">
                <ion-label position="stacked">Bewegungsdruck</ion-label>
                <ion-input formControlName="movingForce"
                           inputmode="decimal"
                           type="number"></ion-input>
            </ion-col>
            <ion-col class="input">
                <ion-label position="stacked">Fügedruck</ion-label>
                <ion-input formControlName="movingPressure"
                           inputmode="decimal"
                           type="number"></ion-input>
            </ion-col>
        </ion-row>

        <ion-row>
            <ion-col class="title" sizeMd="12" sizeXs="12">
                <h4>Andere</h4>
            </ion-col>
        </ion-row>

        <ion-row class="ion-margin">
            <ion-col class="input">
                <ion-label position="stacked">Wulsthöhe (mm)</ion-label>
                <ion-input formControlName="beadHeight"
                           inputmode="decimal"
                           type="number"></ion-input>
            </ion-col>
            <ion-col class="input">
                <ion-label position="stacked">Anwärmzeit (s)</ion-label>
                <ion-input formControlName="warmUpDuration"
                           inputmode="decimal"
                           type="number"></ion-input>
            </ion-col>
        </ion-row>

        <ion-row class="ion-margin">
            <ion-col class="input">
                <ion-label position="stacked">Geschweisst um (Uhrzeit)</ion-label>
                <ion-input formControlName="weldedAroundAt"
                           (change)='calculate()'
                           type="time"></ion-input>
            </ion-col>
            <ion-col class="input">
                <ion-label position="stacked">Abkühlzeit (ganze Minuten)</ion-label>
                <ion-input formControlName="collingDownDuration" (change)='calculate()' inputmode="decimal" type="number"></ion-input>
            </ion-col>
            <ion-col class="input">
                <ion-label position="stacked">Ausspannen um (Uhrzeit)</ion-label>
                <ion-input formControlName="relaxAt" type="time"></ion-input>
            </ion-col>
        </ion-row>

        <ion-row class="ion-margin">
            <ion-col class="input">
                <ion-label position="stacked">Umstellzeit (s)</ion-label>
                <ion-input formControlName="changeOverTime" inputmode="decimal" type="number"></ion-input>
            </ion-col>
            <ion-col class="input" sizeXs="6">
                <ion-label position="stacked" i18n>Energiequelle</ion-label>
                <ion-select class="select" formControlName="powerSource" multiple="false">
                    <ion-select-option value="generator">
                        Generator
                    </ion-select-option>
                    <ion-select-option value="power grid">
                        Netz
                    </ion-select-option>
                </ion-select>
            </ion-col>
        </ion-row>

        <ion-row class="ion-margin">
            <ion-col class="input" sizeMd="6">
                <ion-label position="stacked">Unterschrift</ion-label>
                <ion-input formControlName="welderInitials" type="string"></ion-input>
            </ion-col>
        </ion-row>

        <ion-row class="ion-margin">
            <ion-col>
                <ion-checkbox [(ngModel)]="isLastPipeChecked" checked="false" color="dark"
                              formControlName="isLastPipe"></ion-checkbox>
                <ion-label i18n>Letzte Schweissnaht</ion-label>
            </ion-col>
        </ion-row>

        <ion-row class="ion-margin" *ngIf="isLastPipeChecked">
            <ion-col class="input" sizeMd="3" sizeXs="5">
                <ion-label position="floating" i18n>Gesamtlänge (m)</ion-label>
                <ion-input formControlName="totalLength" inputmode="decimal"
                           type="number"></ion-input>
            </ion-col>
        </ion-row>

        <ion-row class="ion-margin">
            <ion-col class="input" sizeMd="6">
                <ion-label class="ion-invalid" *ngIf="weldForm['$error_messages']">
                    {{weldForm['$error_messages']}}
                </ion-label>
            </ion-col>
        </ion-row>

        <ion-row align-items-center class="button-row ion-justify-content-center">
            <ion-col sizeXs="6" sizeMd="6" sizeLg="2" text-center>
                <ion-button color="dark" expand="full" type="submit" i18n
                    class="ion-text-uppercase" [disabled]="weldForm['$submitting']" type="submit">
                    <ion-spinner *ngIf="weldForm['$submitting']"></ion-spinner>
                    <ion-label *ngIf="!weldForm['$submitting']">Speichern</ion-label>
                </ion-button>
            </ion-col>
        </ion-row>

    </form>
</ion-content>
