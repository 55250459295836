import {OnInit} from '@angular/core';
import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import {ConstructionSiteService} from '../../services/construction-site/construction-site.service';
import {UtilityService} from '../../services/utility/utility.service';
import {WeldService} from '../../services/weld/weld.service';
import {FormsService} from "../../services/form/forms.service";

@Component({
    selector: 'app-add-welding-modal',
    templateUrl: './add-welding-modal.component.html',
    styleUrls: ['./add-welding-modal.component.scss'],
})
export class AddWeldingModalComponent implements OnInit {

    @Input() siteId: number;
    @Input() reportId: number;

    public weldForm = new FormGroup({
        date: new FormControl(null, [Validators.required]),
        temperature: new FormControl(null, [Validators.required]),
        heatingElementTemperature: new FormControl(null, [Validators.required]),
        movingForce: new FormControl(null, [Validators.required]),
        movingPressure: new FormControl(null, [Validators.required]),
        totalPressure: new FormControl(null),
        beadHeight: new FormControl(null, [Validators.required]),
        collingDownDuration: new FormControl(null, [Validators.required]),
        warmUpDuration: new FormControl(null, [Validators.required]),
        relaxAt: new FormControl({value:'', disabled: true}, [Validators.required]),
        changeOverTime: new FormControl(null, [Validators.required]),
        powerSource: new FormControl(null, [Validators.required]),
        welderInitials: new FormControl(null, [Validators.required]),
        weldedAroundAt: new FormControl(null, [Validators.required]),
        isLastPipe: new FormControl(false, [Validators.required]),
        totalLength: new FormControl(),
        weldingReport: new FormControl('')
    });

    public mapFieldToColumns = {
        date: 'date',
        temperature: 'temperature',
        heating_element_temperature: 'heatingElementTemperature',
        moving_force: 'movingForce',
        moving_pressure: 'movingPressure',
        total_pressure: 'totalPressure',
        bead_height: 'beadHeight',
        colling_down_duration: 'collingDownDuration',
        warm_up_duration: 'warmUpDuration',
        relax_at: 'relaxAt',
        changeover_time: 'changeOverTime',
        power_source: 'powerSource',
        welder_initials: 'welderInitials',
        welded_around_at: 'weldedAroundAt',
        is_last_pipe: 'isLastPipe',
        total_length: 'totalLength',
        welding_report: 'weldingReport',
    }

    isLastPipeChecked = false;

    constructor(private weldService: WeldService,
                private modalController: ModalController,
                private constructionSiteService: ConstructionSiteService,
                private utilityService: UtilityService,
                private forms: FormsService
    ) {
    }

    ngOnInit(): void {
        const todayDate = new Date().toISOString();
        this.weldForm.patchValue({
            date: todayDate,
        });

        const totalLength = this.weldForm.get('totalLength');

        this.weldForm.get('isLastPipe').valueChanges
            .subscribe(isLastPipe => {
                if (isLastPipe) {
                    totalLength.setValidators([Validators.required]);
                } else {
                    totalLength.setValidators([]);
                }
            });
    }

    calculate() {
        const weldedAroundAt = this.utilityService.convertTimeToDate(this.weldForm.controls.weldedAroundAt.value)
        weldedAroundAt.setMinutes(weldedAroundAt.getMinutes() + this.weldForm.controls.collingDownDuration.value);
        const relaxAt = this.utilityService.convertDateToTime(weldedAroundAt);
        this.weldForm.patchValue({
            relaxAt: relaxAt
        })
    }

    saveWeld(event, form) {

        if (!this.forms.defaultValidate(event, form)) {
            return false;
        }

        this.weldForm.get('weldingReport').setValue(this.reportId);

        const weldData = Object.assign({}, this.weldForm.getRawValue());

        const weldToSave = {
            date: this.constructionSiteService.formatDate(weldData.date),
            temperature: weldData.temperature,
            heating_element_temperature: weldData.heatingElementTemperature,
            moving_force: weldData.movingForce,
            moving_pressure: weldData.movingPressure,
            total_pressure: weldData.totalPressure,
            bead_height: weldData.beadHeight,
            colling_down_duration: weldData.collingDownDuration,
            warm_up_duration: weldData.warmUpDuration,
            relax_at: this.utilityService.convertTimeToTimestamp(weldData.relaxAt),
            changeover_time: weldData.changeOverTime,
            power_source: weldData.powerSource,
            welder_initials: weldData.welderInitials,
            welded_around_at: this.utilityService.convertTimeToTimestamp(weldData.weldedAroundAt),
            is_last_pipe: weldData.isLastPipe,
            total_length: weldData.totalLength,
            welding_report: weldData.weldingReport,
        };


        this.weldService
            .save(this.siteId, this.reportId, weldToSave)
            .subscribe(weld => this.modalController.dismiss(weld), error => {
                this.forms.defaultEndProcess(form, error, {skip_success: true}, this.mapFieldToColumns);
            }, () => {
                this.forms.defaultEndProcess(form, {}, {skip_success: true});
            });
    }

}
