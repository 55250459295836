import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import config from '../../configs';
import DrillingModel from '../drilling/drilling.model';
import {HttpService} from '../http/http.service';
import WeldModel from './weld.model';
import Cookies from 'js-cookie';


@Injectable({
    providedIn: 'root'
})
export class WeldService {

    constructor(private httpService: HttpService) {
    }

    public getAll(siteId: number, reportId: number): Observable<Array<WeldModel>> {
        return this.httpService
            .get<Array<WeldModel>>(`${config.apiHost}/construction-sites/${siteId}/welding-reports/${reportId}/welds/`)
            .pipe(
                map(welds => welds.map(weld => new WeldModel(weld)))
            );
    }

    public save(siteId: number, reportId: number, body: any): Observable<WeldModel> {
        return this.httpService
            .post<WeldModel>(`${config.apiHost}/construction-sites/${siteId}/welding-reports/${reportId}/welds/`, body)
            .pipe(
                map(weld => new WeldModel(weld))
            );
    }

    public getOne(constructionId: number, reportId: number, weldId: number) {
        return this.httpService.get<WeldModel>(`${config.apiHost}/construction-sites/${constructionId}/welding-reports/${reportId}/welds/${weldId}/`)
            .pipe(
                map((weld) => new WeldModel(weld))
            );
    }

    public update(siteId: number, reportId: number, weldId: number, body: WeldModel) {
        return this.httpService
            .put<WeldModel>(`${config.apiHost}/construction-sites/${siteId}/welding-reports/${reportId}/welds/${weldId}/`, body)
            .pipe(
                map((weld: WeldModel) => new WeldModel(weld))
            );
    }

    public updatePipe(constructionId: number, reportId: number, body: DrillingModel) {
        // tslint:disable-next-line:max-line-length
        return this.httpService.put<DrillingModel>(`${config.apiHost}/construction-sites/${constructionId}/welding-reports/${reportId}/`, body)
            .pipe(
                map((drilling: DrillingModel) => new DrillingModel(drilling))
            );
    }

    public delete(siteId: number, reportId: number, weldId: number) {
        return this.httpService
            .delete<Array<WeldModel>>(`${config.apiHost}/construction-sites/${siteId}/welding-reports/${reportId}/welds/${weldId}/`);
    }

}
