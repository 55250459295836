import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';
import {AuthGuard} from 'src/app/guards/auth.guard';
import {PipeCardModule} from '../../shared/pipe-card/pipe-card.module';
import {DrillingReportsPage} from './drilling-reports/drilling-reports.page';
import {DrillingDetailsPage} from './drilling-details/drilling-details.page';

import {DrillingPage} from './drilling.page';

const routes: Routes = [
    {
        path: '',
        component: DrillingPage,
        canActivate: [AuthGuard],
    },
    {
        path: ':reportId',
        component: DrillingReportsPage,
        canActivate: [AuthGuard],
    },
    {
        path: ':reportId/:drillingId/:pipeIndex',
        component: DrillingDetailsPage,
        canActivate: [AuthGuard],
    }
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        PipeCardModule,
        IonicModule,
        RouterModule.forChild(routes)
    ],
    declarations: [
        DrillingPage,
        DrillingReportsPage,
        DrillingDetailsPage
    ]
})
export class DrillingPageModule {
}
