import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {PipeCardComponent} from './pipe-card.component';


@NgModule({
    declarations: [PipeCardComponent],
    exports: [PipeCardComponent],
    imports: [
        CommonModule,
        FormsModule,
        RouterModule,
        IonicModule,
    ]
})
export class PipeCardModule {
}
