import {registerLocaleData} from '@angular/common';
import {DatePipe} from '@angular/common';
import {HttpClientModule} from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import {LOCALE_ID} from '@angular/core';
import {TRANSLATIONS_FORMAT} from '@angular/core';
import {TRANSLATIONS} from '@angular/core';
import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouteReuseStrategy, RouterModule} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {ErrorHandler} from '@angular/core';
import * as Sentry from '@sentry/angular';

import {IonicModule, IonicRouteStrategy} from '@ionic/angular';
import {AddTimeModalModule} from 'src/app/construction-sites/add-time-modal/add-time-modal.module';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {LoadingBarHttpClientModule} from '@ngx-loading-bar/http-client';

import {AppComponent} from './app.component';
import {ConstructionSitePageModule} from './construction-site/construction-site.module';
import {DrillingPageModule} from './construction-site/drilling/drilling.module';
import {WeldingPageModule} from './construction-site/welding/welding.module';
import {ConstructionSitesPageModule} from './construction-sites/construction-sites.module';
import {MainLayoutPageModule} from './main-layout/main-layout.module';
import {DismissAllGuard} from './shared/guards/dismiss-all.guard';
import {ScrollService} from "./services/scroll/scroll.service";
import {FormsService} from "./services/form/forms.service";


registerLocaleData(localeDe, 'de', localeDeExtra);


// @ts-ignore
const germanTranslations = require('raw-loader!../locale/messages.de.xlf').default;


@NgModule({
    declarations: [AppComponent],
    entryComponents: [],
    imports: [
        BrowserModule,
        RouterModule,
        IonicModule.forRoot(
            {
                scrollAssist: false
            }
        ),
        AppRoutingModule,
        MainLayoutPageModule,
        ConstructionSitesPageModule,
        ConstructionSitePageModule,
        WeldingPageModule,
        BrowserAnimationsModule,
        DrillingPageModule,
        HttpClientModule,
        AddTimeModalModule,
        LoadingBarHttpClientModule,
        ServiceWorkerModule.register('/static/www/ngsw-worker.js', {
            enabled: environment.production
        })
    ],
    providers: [
        StatusBar,
        SplashScreen,
        DismissAllGuard,
        DatePipe,
        ScrollService,
        FormsService,
        {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
        {provide: TRANSLATIONS, useValue: germanTranslations},
        {provide: TRANSLATIONS_FORMAT, useValue: 'xlf'},
        {provide: LOCALE_ID, useValue: 'de'},
        {provide: ErrorHandler, useValue: Sentry.createErrorHandler({showDialog: true}),
    },
    ],
    bootstrap: [AppComponent]
})

// export function createTranslateLoader(http: HttpClient) {
//     return new TranslateHttpLoader(http, './/static/assets/i18n/', '.json');
// }
export class AppModule {
}
