import {WorkEntryModel} from './work-entry.model';

export class WorkerReportModel {
    public id: number;
    public firstName: string;
    public lastName: string;
    public totalTime: string;
    public isInactive: string;
    public workEntries: Array<WorkEntryModel>;

    constructor(props) {
        this.id = props.id;
        this.firstName = props.first_name;
        this.lastName = props.last_name;
        this.totalTime = props.travel_time;
        //this.isInactive = props.is_inactive;
        this.workEntries = props.work_entries.map(workEntry => new WorkEntryModel(workEntry));
    }
}
