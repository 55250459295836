import {OnInit} from '@angular/core';
import {Component, Input} from '@angular/core';
import {ValidatorFn} from '@angular/forms';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalController, ToastController} from '@ionic/angular';
import {WorkEntryModel} from 'src/app/services/time-tracking.service';
import {TimeTrackingService} from 'src/app/services/time-tracking.service';
import {UtilityService} from 'src/app/services/utility/utility.service';


const dateToday = new Date();
dateToday.setHours(0);
dateToday.setMinutes(0);
dateToday.setSeconds(0);
dateToday.setMilliseconds(0);

let startDate = new Date(dateToday);
startDate.setDate(new Date().getDate() - 7);


@Component({
    selector: 'app-add-time-modal',
    templateUrl: './add-time-modal.component.html',
    styleUrls: ['./add-time-modal.component.scss'],
})
export class AddTimeModalComponent implements OnInit {
    @Input() userId: number;

    dates: Date[] = [];
    dateCurrent = dateToday;
    dateIndexDiff = 1;

    currentEntry: WorkEntryModel | null;

    isHistoryNetworkLoading = false;
    workTimeTotal: string = '00:00';
    drivingTimeTotal: string = '00:00';
    
    form = new FormGroup(
        {
            id: new FormControl(null),
            date: new FormControl(this.dateCurrent),
            workType: new FormControl('w', [
                Validators.required
            ]),
            workStart: new FormControl('07:00', [
                Validators.required,
                this.timeFractionValidation(),
            ]),
            workEnd: new FormControl('17:00', [
                Validators.required,
                this.timeFractionValidation(),
            ]),
            lunchAndBreaks: new FormControl('01:00', [
                Validators.required,
                this.timeFractionValidation(),
            ]),
            drivingTime: new FormControl('00:00', [
                Validators.required,
                this.timeFractionValidation(),
            ]),
            location: new FormControl('', [
                Validators.required
            ]),
        },
        [],
    );

    constructor(
        public modalController: ModalController,
        private timeTrackingService: TimeTrackingService,
        private utilityService: UtilityService,
        private toastController: ToastController,
    ) {
    }

    async ngOnInit() {
        startDate = await this.timeTrackingService.getFirstAvailableDate(this.userId);
        this.dates = this.getDates(startDate, dateToday);

        this.form.valueChanges.subscribe((form) => {
            this.recalculateWorkTime(this);
        });

        await this.loadEntryFromDateIfAvailable(this.dateCurrent);

        this.recalculateWorkTime(this);
    }

    async openPrevEntry() {
        if (this.isPrevButtonDisabled() || this.dateCurrent === undefined) {
            return;
        }

        this.dateIndexDiff += 1;

        this.dateCurrent = this.dates[this.dates.length - this.dateIndexDiff];
        await this.loadEntryFromDateIfAvailable(this.dateCurrent);
    }

    isAllowedToEdit(): boolean {
        let earliestDate = new Date(dateToday);
        earliestDate.setDate(new Date().getDate() - 2);
        return this.dateCurrent >= earliestDate;
    }

    async openNextEntry() {
        if (this.isNextButtonDisabled() || this.dateCurrent === undefined) {
            return;
        }

        this.dateIndexDiff -= 1;
        this.dateCurrent = this.dates[this.dates.length - this.dateIndexDiff];
        await this.loadEntryFromDateIfAvailable(this.dateCurrent);
    }

    recalculateWorkTime(self: AddTimeModalComponent) {
        const workType = self.form.controls['workType'].value;
        const workStart = self.utilityService.convertTimeToDate(self.form.controls['workStart'].value).getTime();
        const workEnd = self.utilityService.convertTimeToDate(self.form.controls['workEnd'].value).getTime();
        const workTimeMinutes = (workEnd - workStart) / 1000 / 60;
        const lunchMinutes = self.utilityService.convertFormTimeToMinutes(self.form.controls['lunchAndBreaks']);
        const drivingMinutes = self.utilityService.convertFormTimeToMinutes(self.form.controls['drivingTime']);
        const workTotalTimeMinutes = workTimeMinutes - lunchMinutes;
        this.workTimeTotal = this.isWorkday() ? self.utilityService.convertMinutesToTime(workTotalTimeMinutes) : '00:00';
        this.drivingTimeTotal = this.isWorkday() ? self.utilityService.convertMinutesToTime(drivingMinutes) : '00:00';
    }

    async loadEntryFromDateIfAvailable(date: Date = dateToday) {
        this.isHistoryNetworkLoading = true;
        this.currentEntry = await this.timeTrackingService.getEntryByDate(this.userId, date);

        if (this.currentEntry) {
            this.form.controls['id'].setValue(this.currentEntry.id);
            this.form.controls['date'].setValue(this.currentEntry.date);
            this.currentEntry.work_type && this.form.controls['workType'].setValue(this.currentEntry.work_type);
            this.currentEntry.work_start && this.form.controls['workStart'].setValue(this.currentEntry.work_start);
            this.currentEntry.work_end && this.form.controls['workEnd'].setValue(this.currentEntry.work_end);
            this.currentEntry.lunch_and_breaks && this.form.controls['lunchAndBreaks'].setValue(this.currentEntry.lunch_and_breaks);
            this.currentEntry.driving_time && this.form.controls['drivingTime'].setValue(this.currentEntry.driving_time);
            this.currentEntry.location && this.form.controls['location'].setValue(this.currentEntry.location);
        } else {
            this.form.controls['id'].setValue(null);
            this.form.controls['date'].setValue(this.dateCurrent);
            this.form.controls['workType'].setValue('w');
            this.form.controls['workStart'].setValue('07:00');
            this.form.controls['workEnd'].setValue('17:00');
            this.form.controls['lunchAndBreaks'].setValue('01:00');
            this.form.controls['drivingTime'].setValue('00:00');
            this.form.controls['location'].setValue(null);
        }
        this.isHistoryNetworkLoading = false;
    }

    isNextButtonDisabled(): boolean {
        return (
            (this.dateIndexDiff === 1) ||
            this.isHistoryNetworkLoading
        );
    }

    isPrevButtonDisabled(): boolean {
        return (
            (this.dates.length - 1) === this.dateIndexDiff ||
            this.isHistoryNetworkLoading
        );
    }

    isWorkday(): boolean {
        return this.form.controls['workType'].value === 'w'
    }

    async submitForm() {
        await this.form.asyncValidator;
        const isExistingEntryEditing = this.form.controls['id'].value;
        if (isExistingEntryEditing) {
            await this.timeTrackingService.updateEntry(
                this.userId,
                this.form,
            ).then(async data => {
                await this.modalController.dismiss();
            }).catch(async e => {
                const toast = await this.toastController.create({
                    message: 'An issue occurred with the form saving.',
                    duration: 3000,
                });
                await toast.present();
            });
        } else {
            await this.timeTrackingService.addEntry(
                this.userId,
                this.form,
            ).then(async data => {
                await this.modalController.dismiss();
            }).catch(async e => {
                const toast = await this.toastController.create({
                    message: 'An issue occurred with the form saving.',
                    duration: 3000,
                });
                await toast.present();
            });
        }

    }

    currentDateTitle(): string {
        if (this.dateCurrent) {
            return this.dateCurrent.toLocaleDateString(
                'de-ch',
                {
                    weekday: 'long',
                    month: 'long',
                    day: 'numeric',
                });
        } else {
            return 'Loading...';
        }
    }

    currentDateISO(): string {
        if (this.dateCurrent) {
            return this.dateCurrent.toLocaleDateString(
                'de-ch',
                {
                    month: '2-digit',
                    day: 'numeric',
                    year: 'numeric',
                });
        } else {
            return 'Loading...';
        }
    }

    isEditMode(): boolean {
        return Boolean(this.form.controls['id'].value !== null);
    }

    getDates(startDate: Date, endDate: Date): Date[] {
        const dates: Date[] = [];
        for (
            const date = new Date(startDate);
            date <= endDate;
            date.setDate(date.getDate() + 1)
        ) {
            dates.push(new Date(date));
        }
        return dates;
    }

    timeFractionValidation(): ValidatorFn {
        return (control: FormControl): { [key: string]: any } | null => {
            const minutes = this.utilityService.convertFormTimeToMinutes(control);
            const isValid = (minutes % 15 === 0);
            return isValid ? null : {timeInvalid: true};
        };
    }
}
