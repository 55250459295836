import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {first} from 'rxjs/operators';


interface HttpOptions {
    headers?: HttpHeaders | {
        [header: string]: string | string[];
    };
    observe?: 'body';
    params?: HttpParams | {
        [param: string]: string | string[];
    };
    reportProgress?: boolean;
    responseType?: 'json';
    withCredentials?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class HttpService {
    constructor(private http: HttpClient) {
    }

    get<T>(url: string, options?: HttpOptions) {
        return this.http.get<T>(url, options).pipe(first());
    }

    post<T>(url: string, body: any | null, options?: HttpOptions): Observable<T> {
        return this.http.post<T>(url, body, options).pipe(first());
    }

    put<T>(url: string, body: any | null, options?: HttpOptions) {
        return this.http.put<T | object>(url, body, options).pipe(first());
    }

    patch<T>(url: string, body: any | null, options?: HttpOptions) {
        return this.http.patch<T | object>(url, body, options).pipe(first());
    }

    delete<T>(url: string, options?: HttpOptions): Observable<T | object> {
        return this.http.delete(url, options).pipe(first());
    }
}
