import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {MainLayoutPage} from './main-layout/main-layout.page';
import {DismissAllGuard} from './shared/guards/dismiss-all.guard';

const routes: Routes = [
    {
        path: '',
        component: MainLayoutPage,
        canActivate: [AuthGuard],
        canActivateChild: [DismissAllGuard],
        children: [
            {
                path: 'construction-sites',
                loadChildren: () => import('./construction-sites/construction-sites.module').then(m => m.ConstructionSitesPageModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'construction-sites/:constructionSiteId',
                loadChildren: () => import('./construction-site/construction-site.module').then(m => m.ConstructionSitePageModule),
                canActivate: [AuthGuard],
            },
        ]
    },
    {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'login',
        loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
        canActivate: [AuthGuard],
    },
    {
        path: '**',
        loadChildren: () => import('./not-found/not-found.module').then(m => m.NotFoundPageModule)
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
