import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';


if (environment.production) {
    enableProdMode();
    Sentry.init({ dsn: 'https://b408cd5c366a48ada90644f742d25eca@o412698.ingest.sentry.io/5291495' });
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.log(err));
