import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ModalController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {PipeCommunicationService} from '../../../services/communication/pipe-communication.service';
import {CurrentPathService} from '../../../services/current-path/current-path.service';
import DrillingModel from '../../../services/drilling/drilling.model';
import {DrillingService} from '../../../services/drilling/drilling.service';
import {ConstructionSiteService} from '../../../services/construction-site/construction-site.service';
import {AddPipeModalComponent} from '../../../shared/add-pipe-modal/add-pipe-modal.component';

@Component({
    selector: 'app-drilling-reports',
    templateUrl: './drilling-reports.page.html',
    styleUrls: ['./drilling-reports.page.scss'],
})
export class DrillingReportsPage implements OnInit, OnDestroy {
    public drillings: Array<DrillingModel> = [];
    public displayChildren: boolean;
    private subscription: Subscription;
    private constructionSiteId: number;
    private reportId: number;
    public isLastPipeActive = false;
    public siteType: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private modalController: ModalController,
        private drillingService: DrillingService,
        private constructionSiteService: ConstructionSiteService,
        private currentPathService: CurrentPathService,
        private pipeCommunicationService: PipeCommunicationService,
        private router: Router) {
    }

    ngOnInit() {
        this.constructionSiteId = Number(this.activatedRoute.snapshot.parent.parent.params.constructionSiteId);
        this.reportId = Number(this.activatedRoute.snapshot.params.reportId);
        this.subscription = this.currentPathService.currentPathState.subscribe(value => {
            this.displayChildren = value === 'drilling';
        });
        this.getDrillingPipes();
        this.updatePipeData();
        this.siteType = this.constructionSiteService.currentSite.site_type;
    }

    getDrillingPipes() {
        this.drillingService.getAll(this.constructionSiteId, this.reportId).subscribe(drillings => {
            this.isLastPipeActive = drillings.some((drilling) => drilling.isLastPipe);
            this.drillings = drillings.sort((drilling1, drilling2) => (drilling1.id > drilling2.id) ? 1 : -1);
        });
    }

    deletePipe(drillingId: number) {
        this.drillingService.delete(this.constructionSiteId, this.reportId, drillingId)
            .subscribe(drillings => this.drillings = this.drillings.filter(drilling => drilling.id !== drillingId));
    }

    async presentAddPipeModal() {
        if (this.isLastPipeActive) {
            return;
        }
        const modal = await this.modalController.create({
            component: AddPipeModalComponent,
            componentProps: {
                constructionSiteId: this.constructionSiteId,
                reportId: this.reportId
            }
        });
        modal.onDidDismiss()
            .then(modalReturn => {
                if (modalReturn.data && typeof modalReturn.data === 'object') {
                    this.drillings.push(modalReturn.data);
                    this.isLastPipeActive = this.drillings.some((drilling) => drilling.isLastPipe);
                }
            });
        return await modal.present();
    }

    updatePipeData() {
        this.pipeCommunicationService.updateDrillingPipeEmitted$.subscribe(() => {
            this.getDrillingPipes();
        });
    }

    onRedirectBack() {
        this.router.navigate(['/construction-sites/' + this.constructionSiteId + '/drilling']);
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
