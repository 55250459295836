import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth/auth.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        const token = localStorage.getItem(AuthService.AUTH_TOKEN);
        const userData = localStorage.getItem('user');
        
        if (token && !userData) {
            localStorage.clear();
        }

        if (!token && !state.url.includes('login')) {
            return this.router.navigate(['/login']);
        }

        if ((state.url === '/' || state.url.includes('login')) && token) {
            return this.router.navigate(['/construction-sites']);
        }

        return true;
    }

}
