export default class WeldingReportModel {
    public id: number;
    public name: string;
    public outerDiameter: number;
    public nominalDiameter: number;
    public wallThickness: number;
    public material: string;
    public length: number;
    public minCoolingTime: number;
    public constructionSite: number;

    constructor(props) {
        this.id = props.id;
        this.name = props.name;
        this.outerDiameter = props.outer_diameter;
        this.nominalDiameter = props.nominal_diameter;
        this.wallThickness = props.wall_thickness;
        this.material = props.material;
        this.length = props.length;
        this.minCoolingTime = props.min_cooling_time;
        this.constructionSite = props.construction_site;
    }
}
