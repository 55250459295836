import {Component, Input, OnInit} from '@angular/core';
import ConstructionSiteModel from '../../services/construction-site/construction-site.model';

@Component({
    selector: 'app-construction-site-card',
    templateUrl: './construction-site-card.component.html',
    styleUrls: ['./construction-site-card.component.scss'],
})
export class ConstructionSiteCardComponent implements OnInit {
    @Input() constructionData: ConstructionSiteModel;

    constructor() {
    }

    ngOnInit() {
    }

}
