export class WorkPlaceModel {
    public id: number;
    public name: string;


    constructor(props) {
        this.id = props.id;
        this.name = props.name;
    }
}
