import {OnInit} from '@angular/core';
import {Component, Input} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalController} from '@ionic/angular';
import GeologyModel from 'src/app/services/drilling/geology.model';
import {DrillingService} from '../../services/drilling/drilling.service';
import {ConstructionSiteService} from '../../services/construction-site/construction-site.service';
import {FormsService} from "../../services/form/forms.service";

@Component({
    selector: 'app-add-pipe-modal',
    templateUrl: './add-pipe-modal.component.html',
    styleUrls: ['./add-pipe-modal.component.scss'],
})
export class AddPipeModalComponent implements OnInit {
    @Input() constructionSiteId: number;
    @Input() reportId: number;
    @Input() isWelding: boolean;

    geologyTypes: Array<GeologyModel>;
    geologyTypesSelected: Set<string> = new Set();

    public siteType: string;

    public pipeForm = new FormGroup({
        depth: new FormControl('', [Validators.required]),
        direction: new FormControl('', [Validators.required]),
        slope: new FormControl('', [Validators.required]),
        pipe_diameter: new FormControl(),
        geology: new FormControl('', [Validators.required]),
        purgeVolume: new FormControl('', [Validators.required]),
        color: new FormControl('', [Validators.required]),
        drillType: new FormControl('', [Validators.required]),
        moisture: new FormControl('', [Validators.required]),
        comment: new FormControl(''),
        time: new FormControl('', [Validators.required]),
        isLastPipe: new FormControl(false, [Validators.required]),
        totalLength: new FormControl(),
    });

    public mapFieldToColumns = {
        time: 'time',
        depth: 'depth',
        direction: 'direction',
        slope: 'slope',
        pipe_diameter: 'pipe_diameter',
        geology: 'geology',
        purge_volume: 'purgeVolume',
        color: 'color',
        drill_type: 'drillType',
        moisture: 'moisture',
        comment: 'comment',
        is_last_pipe: 'isLastPipe',
        total_length: 'totalLength'
    }

    isLastPipeChecked = false;

    formSubmitted = false;

    constructor(
        private modalController: ModalController,
        private drillingService: DrillingService,
        private constructionSiteService: ConstructionSiteService,
        private forms: FormsService
    ) {
    }

    async ngOnInit() {
        await this.getGeologyTypes();

        const totalLength = this.pipeForm.get('totalLength');

        this.pipeForm.get('isLastPipe').valueChanges
            .subscribe(isLastPipe => {
                if (isLastPipe) {
                    totalLength.setValidators([Validators.required]);
                } else {
                    totalLength.setValidators([]);
                }
            });

        this.siteType = this.constructionSiteService.currentSite.site_type;
        if(this.siteType !== 'f') {
            this.pipeForm.get('direction').clearValidators();
            this.pipeForm.get('direction').updateValueAndValidity();
            this.pipeForm.get('slope').clearValidators();
            this.pipeForm.get('slope').updateValueAndValidity();
            this.pipeForm.get('geology').clearValidators();
            this.pipeForm.get('geology').updateValueAndValidity();
            this.pipeForm.get('purgeVolume').clearValidators();
            this.pipeForm.get('purgeVolume').updateValueAndValidity();
            this.pipeForm.get('time').clearValidators();
            this.pipeForm.get('time').updateValueAndValidity();
        }
        if(this.siteType !== 'e') {
            this.pipeForm.get('color').clearValidators();
            this.pipeForm.get('color').updateValueAndValidity();
            this.pipeForm.get('drillType').clearValidators();
            this.pipeForm.get('drillType').updateValueAndValidity();
            this.pipeForm.get('moisture').clearValidators();
            this.pipeForm.get('moisture').updateValueAndValidity();
        }

    }

    onSave(event, form) {

        this.formSubmitted = true;
        if (!this.forms.defaultValidate(event, form)) {
            return false;
        }

        const pipeData = Object.assign({}, this.pipeForm.value);

        const pipeToSave = {
            time: parseInt(pipeData.time, 10),
            depth: pipeData.depth,
            direction: pipeData.direction,
            slope: parseInt(pipeData.slope, 10),
            pipe_diameter: pipeData.pipe_diameter,
            geology: pipeData.geology,
            purge_volume: parseInt(pipeData.purgeVolume, 10),
            color: pipeData.color,
            drill_type: pipeData.drillType,
            moisture: pipeData.moisture,
            comment: pipeData.comment,
            is_last_pipe: pipeData.isLastPipe,
            total_length: pipeData.totalLength,
            drilling_report: this.reportId
        };

        this.drillingService.save(this.constructionSiteId, this.reportId, pipeToSave as any)
            .subscribe(async (pipe) => {
                await this.modalController.dismiss(pipe);
            }, error => {
                this.forms.defaultEndProcess(form, error, {skip_success: true}, this.mapFieldToColumns);
            }, () => {
                this.forms.defaultEndProcess(form, {}, {skip_success: true});
            });
    }

    async getGeologyTypes() {
        this.geologyTypes = await this.drillingService.getGeologyTypes().toPromise();
    }

    onGeologyChanged(event: CustomEvent) {
        const isChecked = event.detail.checked;
        if (isChecked) {
            this.geologyTypesSelected.add(event.detail.value);
        } else {
            this.geologyTypesSelected.delete(event.detail.value);
        }
        const valueStr = Array.from(this.geologyTypesSelected).join(', ');
        this.pipeForm.controls['geology'].setValue(valueStr);
    }
}
