import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import config from '../../configs';
import {HttpService} from '../http/http.service';
import WeldingReportModel from './welding-report.model';

@Injectable({
    providedIn: 'root'
})
export class WeldingReportService {

    constructor(private httpService: HttpService) {
    }

    public getAll(constructionId: number) {
        return this.httpService.get<Array<WeldingReportModel>>(`${config.apiHost}/construction-sites/${constructionId}/welding-reports/`)
            .pipe(map(pipes => pipes.map(pipe => new WeldingReportModel(pipe))));
    }
}
