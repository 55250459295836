import {UtilityService} from '../utility/utility.service';

export default class WeldModel {
    public id: number;
    public date: string;
    public temperature: number;
    public heatingElementTemperature: number;
    public movingForce: number;
    public movingPressure: number;
    public totalPressure: number;
    public beadHeight: number;
    public collingDownDuration: number;
    public warmUpDuration: number;
    public relaxAt: string;
    public changeOverTime: number;
    public powerSource: string;
    public welderInitials: string;
    public weldedAroundAt: string;
    public isLastPipe: boolean;
    public totalLength: number;
    public weldingReport: number;

    constructor(props) {
        const utilityService = new UtilityService();
        this.id = props.id;
        this.date = props.date;
        this.temperature = props.temperature;
        this.heatingElementTemperature = props.heating_element_temperature;
        this.movingForce = props.moving_force;
        this.movingPressure = props.moving_pressure;
        this.totalPressure = props.total_pressure;
        this.beadHeight = props.bead_height;
        this.collingDownDuration = props.colling_down_duration;
        this.warmUpDuration = props.colling_down_duration;
        this.relaxAt = utilityService.convertTimestampToTime(props.relax_at);
        this.changeOverTime = props.changeover_time;
        this.powerSource = props.power_source;
        this.welderInitials = props.welder_initials;
        this.weldedAroundAt = utilityService.convertTimestampToTime(props.welded_around_at);
        this.weldingReport = props.welding_report;
        const isConstructedFromJson = props.isLastPipe === undefined;
        if (isConstructedFromJson) {
            this.isLastPipe = props.is_last_pipe;
            this.totalLength = props.total_length;
        } else {
            this.isLastPipe = props.isLastPipe;
            this.totalLength = props.totalLength;
        }
    }
}

export class WeldPostData {
    // tslint:disable-next-line:variable-name
    public pipe_ad: number;
    // tslint:disable-next-line:variable-name
    public wall_thickness: number;
    public date: Date;
    public temperature: number;
    // tslint:disable-next-line:variable-name variable-name
    public heating_element_temperature: number;
    // tslint:disable-next-line:variable-name
    public moving_force: number;
    // tslint:disable-next-line:variable-name
    public moving_pressure: number;
    // tslint:disable-next-line:variable-name
    public total_pressure: number;
    // tslint:disable-next-line:variable-name variable-name
    public bead_height: number;
    // tslint:disable-next-line:variable-name
    public colling_down_duration: number;
    // tslint:disable-next-line:variable-name
    public warm_up_duration: number;
    // tslint:disable-next-line:variable-name
    public relax_at: Date;
    // tslint:disable-next-line:variable-name
    public changeover_time: number;
    public power_source: string
    // tslint:disable-next-line:variable-name
    public welder_initials: string;
    // tslint:disable-next-line:variable-name
    public welded_around_at: Date;
    public welding_report: number;

    constructor(props) {
        this.pipe_ad = props.pipeAD;
        this.wall_thickness = props.wallThickness;
        this.date = props.date;
        this.temperature = props.temperature;
        this.heating_element_temperature = props.heatingElementTemperature;
        this.moving_force = props.movingForce;
        this.moving_pressure = props.movingPressure;
        this.total_pressure = props.totalPressure;
        this.bead_height = props.beadHeight;
        this.colling_down_duration = props.collingDownDuration;
        this.warm_up_duration = props.warmUpDuration;
        this.relax_at = props.relaxAt;
        this.changeover_time = props.changeOverTime;
        this.power_source = props.powerSource;
        this.welder_initials = props.welderInitials;
        this.welded_around_at = props.weldedAroundAt;
        this.welding_report = props.weldingReport;
    }

}
