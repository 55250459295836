import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ReactiveFormsModule} from '@angular/forms';
import {IonicModule} from '@ionic/angular';
import {AddTimeModalComponent} from 'src/app/construction-sites/add-time-modal/add-time-modal.component';
import {HeaderModule} from 'src/app/shared/header/header.module';


@NgModule({
    entryComponents: [AddTimeModalComponent],
    exports: [AddTimeModalComponent],
    declarations: [AddTimeModalComponent],
    imports: [
        CommonModule,
        IonicModule,
        HeaderModule,
        ReactiveFormsModule
    ]
})
export class AddTimeModalModule {
}
