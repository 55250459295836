import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AlertController} from '@ionic/angular';
import {PipeCommunicationService} from '../../services/communication/pipe-communication.service';
import DrillingModel from '../../services/drilling/drilling.model';
import {WeldService} from '../../services/weld/weld.service';
import WeldingReportModel from '../../services/welding-report/welding-report.model';

@Component({
    selector: 'app-pipe-card',
    templateUrl: './pipe-card.component.html',
    styleUrls: ['./pipe-card.component.scss'],
})
export class PipeCardComponent implements OnInit, OnChanges {
    @Input() isWelding: boolean;
    @Input() isEarthProbe: boolean;
    @Input() isLastPipe: boolean;
    @Input() data: WeldingReportModel | DrillingModel = null;
    @Input() pipeIndex: number;
    @Input() constructionSiteId: number;
    @Output() delete = new EventEmitter<number>();
    public weldsNumber = 0;

    constructor(
        private alertController: AlertController,
        private weldService: WeldService,
        private pipeCommunicationService: PipeCommunicationService
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && changes.isWelding) {
            this.getWeldsNumber();
        }
    }

    ngOnInit(): void {
        this.updateWeldsNumber();
    }

    async deletePipe(pipeId) {
        const alert = await this.alertController.create({
            message: 'Delete the pipe and all its welds?',
            buttons: [
                {
                    text: 'Cancel',
                    role: 'cancel',
                    cssClass: 'secondary',
                },
                {
                    text: 'Delete',
                    handler: () => {
                        this.onDelete(pipeId);
                    }
                }
            ]
        });

        await alert.present();
    }

    onDelete(pipeId) {
        this.weldService.getAll(this.constructionSiteId, pipeId).subscribe(
            welds => {
                welds.forEach(weld => this.deleteWeld(pipeId, weld.id));
            });
        this.delete.emit(pipeId);
    }

    deleteWeld(pipeId: number, weldId: number) {
        this.weldService.delete(this.constructionSiteId, pipeId, weldId).subscribe();
    }

    getWeldsNumber() {
        this.weldService
            .getAll(this.constructionSiteId, this.data.id)
            .subscribe(welds => this.weldsNumber = welds.length);
    }

    updateWeldsNumber() {
        this.pipeCommunicationService.updateWeldEmitted$.subscribe(() => {
            this.getWeldsNumber();
        });
    }
}
