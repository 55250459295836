export class MaterialModel {
    public id: string;
    public name: string;
    public field1Name: string;
    public field1Value: any;
    public field2Name: string;
    public field2Value: any;
    // tslint:disable-next-line:variable-name
    public delivery_slip: any;
    // tslint:disable-next-line:variable-name
    public delivery_slip_data: any;
    material: any;

    constructor(props) {
        this.id = String(props.id);
        this.name = props.name;
        this.field1Name = props.field_1_name;
        this.field1Value = props.field_1_value;
        this.field2Name = props.field_2_name;
        this.field2Value = props.field_2_value;
        this.delivery_slip = props.delivery_slip;
    }
}
