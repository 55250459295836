import {Injectable} from '@angular/core';
import UserModel from './user.model';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    public user: UserModel;

    constructor() {
    }
}
