import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {IonicModule} from '@ionic/angular';
import {AddWeldingModalModule} from 'src/app/shared/add-welding-modal/add-welding-modal.module';
import {ConstructionSiteCardComponent} from './construction-site-card/construction-site-card.component';

import {ConstructionSitesPage} from './construction-sites.page';

const routes: Routes = [
    {
        path: '',
        component: ConstructionSitesPage
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        RouterModule.forChild(routes),
        AddWeldingModalModule,
    ],
    declarations: [ConstructionSitesPage, ConstructionSiteCardComponent]
})
export class ConstructionSitesPageModule {
}
