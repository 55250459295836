import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';
import {IonicModule} from '@ionic/angular';
import {AuthGuard} from 'src/app/guards/auth.guard';
import {AddPipeModalModule} from '../../shared/add-pipe-modal/add-pipe-modal.module';
import {AddWeldingModalModule} from '../../shared/add-welding-modal/add-welding-modal.module';
import {PipeCardModule} from '../../shared/pipe-card/pipe-card.module';

import {WeldingPage} from './welding.page';

const routes: Routes = [
    {
        path: '',
        component: WeldingPage,
        canActivate: [AuthGuard],
    },
    {
        path: ':reportId',
        children: [
            {
                path: '',
                loadChildren: () => import('./welds/welds.module').then(m => m.WeldsPageModule)
            }
        ],
        canActivate: [AuthGuard],
    }
];

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        PipeCardModule,
        AddPipeModalModule,
        AddWeldingModalModule,
        RouterModule.forChild(routes),
        ReactiveFormsModule,
    ],
    declarations: [
        WeldingPage
    ]
})
export class WeldingPageModule {
}
