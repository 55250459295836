import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import {MachineModel} from 'src/app/services/shared-models/machine.model';
import UserModel from 'src/app/services/user/user.model';
import config from '../../configs';
import {HttpService} from '../http/http.service';
import { WorkPlaceModel } from '../shared-models/workplace.model';
import ConstructionSiteImagesModel from './construction-site-images.model';
import ConstructionSiteModel from './construction-site.model';
import Cookies from 'js-cookie';

@Injectable({
    providedIn: 'root'
})
export class ConstructionSiteService {
    static BASE_URL = `${config.apiHost}/construction-sites`;
    public currentSite: ConstructionSiteModel = null;

    constructor(private http: HttpService) {
    }

    getConstructionSites() {
        return this.http
            .get<Array<ConstructionSiteModel>>(`${config.apiHost}/construction-sites`)
            .pipe(
                map(data =>
                    data.map(
                        constructionData => new ConstructionSiteModel(constructionData)
                    )
                )
            );
    }

    getOne(id: number): Observable<ConstructionSiteModel> {
        return this.http
            .get<ConstructionSiteModel>(`${config.apiHost}/construction-sites/${id}`)
            .pipe(
                map(data => new ConstructionSiteModel(data)),
                tap(site => (this.currentSite = site))
            );
    }

    getImages(id: number) {
        return this.http
            .get<Array<ConstructionSiteImagesModel>>(`${config.apiHost}/construction-sites/${id}/image`);
    }

    uploadImage(id: number, data: any) {
        return this.http.post(
            `${config.apiHost}/construction-sites/${id}/image/`,
            this.toFormData(data)
        );
    }

    getDocuments(id: number) {
        return this.http.get(
            `${config.apiHost}/construction-sites/${id}/documents`
        );
    }

    getAllMachines(): Promise<MachineModel[]> {
        return this.http
            .get(`${config.apiHost}/machines/`)
            .pipe(
                map((machineRaw: Array<any>) => {
                    let machines: MachineModel[] = machineRaw.map(
                        machineData => new MachineModel(machineData)
                    )
                    return machines;
                }),
            )
            .toPromise();
    }

    getAllWorkPlaces(): Promise<WorkPlaceModel[]> {
        return this.http
            .get(`${config.apiHost}/workplaces/`)
            .pipe(
                map((workplaceRaw: Array<any>) => {
                    let workplaces: WorkPlaceModel[] = workplaceRaw.map(
                        workplaceData => new WorkPlaceModel(workplaceData)
                    )
                    return workplaces;
                }),
            )
            .toPromise();
    }

    getWorkplace(id: number): Observable<WorkPlaceModel> {
        return this.http
            .get<WorkPlaceModel>(`${config.apiHost}/workplaces/${id}`)
            .pipe(
                map(data => new WorkPlaceModel(data))
            );
    }

    getWorker(id: number): Observable<UserModel> {
        return this.http
            .get<UserModel>(`${config.apiHost}/users/${id}`)
            .pipe(
                map(data => new UserModel(data))
            );
    }

    getAllWorkers(): Promise<UserModel[]> {
        return this.http
            .get(`${config.apiHost}/users/`)
            .pipe(
                map((workersRaw: Array<any>) => {
                    let workers: UserModel[] = workersRaw.map(
                        workerData => new UserModel(workerData)
                    )
                    workers = workers.filter(worker => worker.firstName !== '')
                    return workers;
                }),
            )
            .toPromise();
    }

    async addWorker(siteId: number, workerId: number): Promise<any> {
        const site: ConstructionSiteModel = await this.getOne(siteId).toPromise();
        const siteWorkersIds: number[] = site.workers.map(worker => worker.id);
        siteWorkersIds.push(workerId);
        const data = {workers_ids: siteWorkersIds};
        return this.http
            .patch(`${config.apiHost}/construction-sites/${siteId}/`,
            data)
            .toPromise();
    }

    async addMachine(siteId: number, machineId: number): Promise<any> {
        const site: ConstructionSiteModel = await this.getOne(siteId).toPromise();
        const siteMachinesIds: number[] = site.allocatedMachines.map(machine => machine.id);
        siteMachinesIds.push(machineId);
        const data = {allocated_machines_ids: siteMachinesIds};
        return this.http
            .patch(`${config.apiHost}/construction-sites/${siteId}/`,
            data)
            .toPromise();
    }

    formatDate(date) {
        const d = new Date(date);
        let month = '' + (d.getMonth() + 1);
        let day = '' + d.getDate();
        const year = d.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [year, month, day].join('-');
    }

    async collectMachinesAvailable(
        machinesCurrent: Array<MachineModel>
    ): Promise<MachineModel[]> {
        let machinesAll: MachineModel[] = await this.getAllMachines();
        const machinesAvailable = machinesAll.filter(machine => {
            return !machinesCurrent.find(
                machineCurrent => machineCurrent.id === machine.id
            );
        });
        return machinesAvailable;
    }

    private toFormData<T>(formValue: T): FormData {
        const formData: FormData = new FormData();
        for (const key of Object.keys(formValue)) {
            const value = formValue[key];
            formData.append(key, value);
        }
        return formData;
    }
}
