export class WeatherModel {
    public id: number;
    public name: string;
    public icon: string;

    constructor(props) {
        this.id = props.id;
        this.name = props.name;
        this.icon = props.icon;
    }
}
