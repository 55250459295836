export default class UserModel {
    id: number;
    firstName: string;
    lastName: string;

    constructor(props) {
        this.id = props.id;
        this.firstName = props.first_name;
        this.lastName = props.last_name;
    }
}
