import IConfig from './config.interface';
import localConfig from './config.local';
import prodConfig from './config.prod';

let config: IConfig;
const isConnectToLocalAPI = false;


if (isConnectToLocalAPI) {
    config = localConfig;
} else {
    config = prodConfig;
}
export default config;
