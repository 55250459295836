import {ViewEncapsulation} from '@angular/core';
import {Component} from '@angular/core';
import {SplashScreen} from '@ionic-native/splash-screen/ngx';
import {StatusBar} from '@ionic-native/status-bar/ngx';

import {Platform} from '@ionic/angular';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
    constructor(
        private platform: Platform,
        private splashScreen: SplashScreen,
        private statusBar: StatusBar,
        // translate: TranslateService
    ) {
        this.initializeApp();
        // this language will be used as a fallback when a translation isn't found in the current language
        // translate.setDefaultLang('en');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        //  translate.use('en');
    }

    initializeApp() {
        this.platform.ready().then(() => {
            this.statusBar.styleDefault();
            this.splashScreen.hide();
        });
    }
}
