import {Injectable} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import * as moment from 'moment';


@Injectable({
    providedIn: 'root'
})
export class UtilityService {

    constructor() {
    }

    getAllURLParams(pathFromRoot: any): { [key: string]: number } {
        return pathFromRoot.reduce((params, next) => {
            return Object.assign(params, next.params);
        }, {});
    }

    convertTimeToFloat(str: string): number {
        const date = this.convertTimeToDate(str);
        const minutes = (date.getHours() * 60) + date.getMinutes();
        return Number((minutes / 60).toFixed(2));
    }

    convertFloatToTime(num: number): string {
        const duration = moment.duration(num, 'h');
        const hours = String(duration.hours()).padStart(2, '0');
        const minutes = String(duration.minutes()).padStart(2, '0');
        return `${hours}:${minutes}`;
    }

    convertMinutesToTime(minutes: number): string {
        const duration = moment.duration(minutes, 'm');
        const hoursStr = String(duration.hours()).padStart(2, '0');
        const minutesStr = String(duration.minutes()).padStart(2, '0');
        return `${hoursStr}:${minutesStr}`;
    }

    convertTimeToTimestamp(str: string): string {
        const m = moment().format('YYYY-MM-DD');
        const timestamp = moment(m + ' ' + str).format();
        return timestamp;
    }

    convertTimestampToTime(str: string): string {
        const timestamp = new Date(str);
        const hour = ('0' + timestamp.getHours().toString()).slice(-2);
        const minutes = ('0' + timestamp.getMinutes().toString()).slice(-2);
        const time = `${hour}:${minutes}`;
        return time;
    }

    convertTimeToDate(timeRaw: string): Date {
        const date = new Date(`${moment().format('YYYY-MM-DD')}T${timeRaw}`);
        return date;
    }

    convertDateToTime(date: Date): string {
        const hour = ('0' + date.getHours().toString()).slice(-2);
        const minutes = ('0' + date.getMinutes().toString()).slice(-2);
        const time = `${hour}:${minutes}`;
        return time;
    }

    convertTimeToMinutes(time: number): number {
        return time / 1000 / 60;
    }

    convertFormTimeToMinutes(timeControl: AbstractControl): number {
        const date = this.convertTimeToDate(timeControl.value);
        return (date.getHours() * 60) + date.getMinutes();
    }
}
