import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import config from '../../configs';
import {ConstructionSiteService} from '../construction-site/construction-site.service';
import {HttpService} from '../http/http.service';
import {WeatherModel} from '../shared-models/weather.model';
import {DailyReportModel, DailyReportPostData} from './daily-report.model';
import Cookies from 'js-cookie';

@Injectable({
    providedIn: 'root'
})
export class DailyReportService {

    constructor(
        private httpService: HttpService
    ) {
    }

    getAll(siteId: number): Observable<Array<DailyReportModel>> {
        return this.httpService.get<Array<DailyReportModel>>(`${ConstructionSiteService.BASE_URL}/${siteId}/daily-reports`)
            .pipe(
                map((respData: Array<DailyReportModel>) => respData.map(rep => new DailyReportModel(rep)))
            );
    }

    async save(siteId: number, body: DailyReportPostData): Promise<DailyReportModel> {

        const dailyReportModel = await this.httpService
            .post(
                `${ConstructionSiteService.BASE_URL}/${siteId}/daily-reports/`,
                body
            )
            .pipe(
                map((dailyReport: DailyReportModel) => new DailyReportModel(dailyReport))
            )
            .toPromise();
        return dailyReportModel;
    }

    getOne(siteId: number, reportId: number): Observable<DailyReportModel> {

        return this.httpService.get<DailyReportModel>(`${ConstructionSiteService.BASE_URL}/${siteId}/daily-reports/${reportId}`)
            .pipe(
                map((dailyReport) => new DailyReportModel(dailyReport))
            );
    }

    getWeather(): Observable<Array<WeatherModel>> {
        return this.httpService.get<Array<WeatherModel>>(`${config.apiHost}/weather/`)
            .pipe(
                map((respData: Array<WeatherModel>) => respData.map(rep => new WeatherModel(rep)))
            );
    }
}
