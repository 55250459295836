import {Component, OnDestroy, OnInit, ElementRef, ViewChild} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ModalController} from '@ionic/angular';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {CurrentPathService} from '../../services/current-path/current-path.service';
import {WeldService} from '../../services/weld/weld.service';
import WeldingReportModel from '../../services/welding-report/welding-report.model';
import {WeldingReportService} from '../../services/welding-report/welding-report.service';

@Component({
    selector: 'app-welding',
    templateUrl: './welding.page.html',
    styleUrls: ['./welding.page.scss'],
})
export class WeldingPage implements OnInit, OnDestroy {
    @ViewChild('content', {static: false}) private scrollBottom: any;
    constructionSiteId: number;
    displayChildren: boolean;
    isLastPipe: boolean;
    public pipes: Array<WeldingReportModel> = [];
    private subscription: Subscription;
    public isCreatingPipe = false;

    constructor(
        private activatedRoute: ActivatedRoute,
        private reportService: WeldingReportService,
        private weldService: WeldService,
        private modalController: ModalController,
        private currentPathService: CurrentPathService) {
    }

    ngOnInit() {
        this.subscription = this.currentPathService.currentPathState.subscribe(value => {
            this.displayChildren = value === 'welding';
        });
        this.getPipes();
    }

    getPipes(scroll = false) {
        this.constructionSiteId = parseInt(this.activatedRoute.snapshot.parent.parent.parent.paramMap.get('constructionSiteId'), 10);
        this.reportService.getAll(this.constructionSiteId)
            .pipe(map(weldingPipes => weldingPipes.sort((pipe1, pipe2) => (pipe1.id > pipe2.id) ? 1 : -1)))
            .subscribe(pipes => {
                this.pipes = pipes;
                if (scroll) {
                    this.scrollToBottom();
                }
            });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    scrollToBottom(): void {
        try {
            setTimeout(() => {
                this.scrollBottom.scrollToBottom(300);
            }, 0);

        } catch (err) {
            console.log(err);
        }
    }
}
