<ion-content *ngIf="displayChildren">
    <ion-row *ngIf="isLastPipeActive" class="info-box">
        <ion-col class="box">
            <ion-text i18n>This report has been completed because the last pipe has been registered. If you need to extend it
                please uncheck the last pipe attribute on the last entry.
            </ion-text>
        </ion-col>
    </ion-row>
    <ion-row class="pipes">
        <ion-col *ngFor="let drilling of drillings; let index = index;" sizeMd="6" sizeXs="12">
            <app-pipe-card
                    (delete)="deletePipe($event)"
                    [isEarthProbe]="siteType === 'e'"
                    [data]="drilling"
                    [isLastPipe]="drilling.isLastPipe"
                    [pipeIndex]="index">
            </app-pipe-card>
        </ion-col>
    </ion-row>

    <ion-fab horizontal="center" slot="fixed" vertical="bottom" class="center-fab">
        <ion-row class="button-row ion-align-items-center ion-justify-content-center">
            <ion-col class="ion-text-center">
                <ion-button (click)="onRedirectBack()" color="" expand="full"
                    class="ion-text-uppercase" type="button">
                    <ion-label i18n>Zurück</ion-label>
                </ion-button>
            </ion-col>
            <ion-col class="ion-text-center">
                <ion-button (click)="presentAddPipeModal()" [disabled]="isLastPipeActive">
                    <ion-icon name="add" slot="start"></ion-icon>
                    Bohrstange hinzufügen
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-fab>

</ion-content>


<ion-content *ngIf="!displayChildren">
    <router-outlet></router-outlet>
</ion-content>
