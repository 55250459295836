import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {Observable, of} from 'rxjs';
import {AddTimeModalComponent} from 'src/app/construction-sites/add-time-modal/add-time-modal.component';
import ConstructionSiteModel from '../services/construction-site/construction-site.model';
import {ConstructionSiteService} from '../services/construction-site/construction-site.service';

@Component({
    selector: 'app-construction-sites',
    templateUrl: './construction-sites.page.html',
    styleUrls: ['./construction-sites.page.scss'],
})
export class ConstructionSitesPage implements OnInit {
    public constructionSites: Array<ConstructionSiteModel>;
    public constructionSitesFiltered: Observable<Array<ConstructionSiteModel>>;
    public siteType: string = 'f';
    
    constructor(
        private constructionSiteService: ConstructionSiteService,
        public modalController: ModalController,
    ) {
    }

    ngOnInit() {
        this.constructionSitesData();
    }

    siteTypeChanged(type) {
        this.siteType = type;
        this.constructionSitesFiltered = this.constructionSites && of(this.constructionSites.filter((e) => e.site_type === this.siteType))
    }

    async showTimeTrackingModal() {
        const modal = await this.modalController.create({
            component: AddTimeModalComponent,
            componentProps: {
                userId: JSON.parse(localStorage.user).pk,
            },
            cssClass: 'add-time-modal',
        });
        return await modal.present();
    }

    private async constructionSitesData() {
        this.constructionSites = await this.constructionSiteService.getConstructionSites().toPromise();
        this.constructionSitesFiltered = of(this.constructionSites.filter((e) => e.site_type === this.siteType))
    }
}
