import {environment} from '../../environments/environment';

export class Message {
    private not_existing_feature: string;

    constructor() {
        this.not_existing_feature = 'feature not available';
    }

    log(...messages: Array<any>): void {
        if (environment.messages.console) {
            console.error(messages);
        }
    }

    error(...messages: Array<any>): void {
        if (environment.messages.console) {
            console.error('Error:', messages);
        }
    }

    alert(message: string): void {
        if (environment.messages.alert) {
            alert(message);
        }
    }

    confirm(message: string): boolean {
        if (environment.messages.confirm) {
            return confirm(message);
        }
        return false;
    }

    notify(messages: string | Array<string>): void {
        if (environment.messages.notify) {
            console.log('Notify ' + this.not_existing_feature);
        }
    }

    track(messages: string | Array<string>): void {
        if (environment.messages.console) {
            console.error('Track:', messages);
        }
    }
}
