import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from 'src/app/guards/auth.guard';
import {ConstructionSitePage} from './construction-site.page';

const routes: Routes = [
    {
        path: '',
        component: ConstructionSitePage,
        children: [
            {path: '', redirectTo: 'overview', pathMatch: 'full'},
            {
                path: 'overview',
                loadChildren: () => import('./overview/overview.module').then(m => m.OverviewPageModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'welding',
                children: [
                    {
                        path: '',
                        loadChildren: './welding/welding.module#WeldingPageModule',
                        canActivate: [AuthGuard],
                    },
                ],
                canActivate: [AuthGuard],
            },
            {
                path: 'drilling',
                loadChildren: () => import('./drilling/drilling.module').then(m => m.DrillingPageModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'testing',
                loadChildren: () => import('./test-protocol/test-protocol.module').then(m => m.TestProtocolModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'daily-report',
                loadChildren: () => import('./daily-report/daily-report.module').then(m => m.DailyReportPageModule),
                canActivate: [AuthGuard],
            },
            {
                path: 'dimension-report',
                loadChildren: () => import('./dimension-report/dimension-report.module').then(m => m.DimensionReportPageModule),
                canActivate: [AuthGuard],
            },
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ConstructionSitePageRoutingModule {
}
