import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule} from '@angular/forms';

import {IonicModule} from '@ionic/angular';

import {ConstructionSitePage} from './construction-site.page';
import {ConstructionSitePageRoutingModule} from './construction-site.router.module';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        ConstructionSitePageRoutingModule
    ],
    declarations: [ConstructionSitePage]
})
export class ConstructionSitePageModule {
}
