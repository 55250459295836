<ion-content #content>
    <ion-row class="reports">
        <ion-col *ngFor="let report of reports; let index = index;" sizeMd="6" sizeXs="12">
            <ion-card>
                <ion-card-content>
                    <ion-row>
                        <ion-col>
                            <h5>
                                {{report.name ? report.name : "Bohrprotokoll"}}
                            </h5>
                        </ion-col>
                    </ion-row>
            
                    <ion-row class="ion-align-items-center">
                        <ion-col>
                            Datum: {{report.date | date: 'dd.MM.yyyy'}}
                        </ion-col>
                    </ion-row>
            
                    <ion-row>
                        <ion-col text-left>
                            <ion-button class="link" no-padding routerLink="{{report.id}}">
                                DETAILS ANZEIGEN
                            </ion-button>
                        </ion-col>
                    </ion-row>
            
                </ion-card-content>
            </ion-card>            
        </ion-col>
    </ion-row>

    <ion-fab slot="fixed" vertical="bottom">
        <ion-row text-center>
            <ion-col>
                <ion-button (click)="createReport()">
                    <ion-icon name="add" slot="start"></ion-icon>
                    Eintrag Hinzufügen
                </ion-button>
            </ion-col>
        </ion-row>
    </ion-fab>
</ion-content>
