export const environment = {
    production: true,
    messages: {
        console: false,
        alert: false,
        confirm: false,
        notify: false,
        error: false,
        track: false,
        debugg_messages: false
    },
};
