<ion-header>
    <ion-toolbar class="background-color">
        <ion-row class="ion-align-items-center ion-justify-content-between">
            <ion-col>
                <h4>Zeiterfassung</h4>
            </ion-col>
            <ion-col>
                <ion-buttons>
                    <ion-button (click)="modalController.dismiss()">
                        <ion-icon name="close"></ion-icon>
                    </ion-button>
                </ion-buttons>
            </ion-col>
        </ion-row>
    </ion-toolbar>
</ion-header>


<ion-content>
    <form (ngSubmit)="submitForm()" [formGroup]="form">
        <ion-row class="ion-justify-content-between ion-margin date-switcher">
            <ion-col size="1" class="ion-justify-content-center">
                <span
                        class="btn-date-switch"
                        [attr.disabled]="isPrevButtonDisabled()"
                        (click)="openPrevEntry()"
                >‹</span>
            </ion-col>
            <ion-col class="ion-justify-content-center">
                <div class="ion-text-center date-current"
                     [attr.data-is-filled-in]="isEditMode()"
                >{{this.currentDateTitle()}}</div>
            </ion-col>
            <ion-col size="1" class="ion-justify-content-center">
                <span
                        class="btn-date-switch"
                        [attr.disabled]="isNextButtonDisabled()"
                        (click)="openNextEntry()"
                >›</span>
            </ion-col>
        </ion-row>

        <ion-row class="input-row">
            <ion-col class="input">
                <ion-item>
                    <ion-label>Arbeitstyp</ion-label>
                    <ion-select formControlName="workType"
                    [attr.disabled]="!isAllowedToEdit()">
                        <ion-select-option [value]="'w'">
                            Arbeit
                        </ion-select-option>
                        <ion-select-option [value]="'h'">
                            Ferien
                        </ion-select-option>
                        <ion-select-option [value]="'i'">
                            Krankheit
                        </ion-select-option>
                        <ion-select-option [value]="'a'">
                            Unfall
                        </ion-select-option>
                        <ion-select-option [value]="'e'">
                            Ausbildung
                        </ion-select-option>
                        <ion-select-option [value]="'m'">
                            Militärdienst
                        </ion-select-option>
                    </ion-select>
                </ion-item>
            </ion-col>
        </ion-row>

        <ion-row class="input-row" style="padding-top:0">
            <ion-col class="input">
                <ion-label position="stacked">Arbeitsbeginn</ion-label>
                <ion-input formControlName="workStart" type="time" step="900"
                           [attr.readonly]="!isAllowedToEdit()"
                           [attr.disabled]="!isWorkday()"></ion-input>
                <div *ngIf="form.get('workStart').hasError('timeInvalid')" class="error-message" i18n>
                    Invalid time format, use 15m increments.
                </div>
            </ion-col>
            <ion-col class="input">
                <ion-label position="stacked">Arbeitsende</ion-label>
                <ion-input formControlName="workEnd" type="time" step="900"
                           [attr.readonly]="!isAllowedToEdit()"
                           [attr.disabled]="!isWorkday()"></ion-input>
                <div *ngIf="form.get('workEnd').hasError('timeInvalid')" class="error-message" i18n>
                    Invalid time format, use 15m increments.
                </div>
            </ion-col>
        </ion-row>

        <ion-row class="border-row input-row">
            <ion-col class="input">
                <hr>
            </ion-col>
        </ion-row>

        <ion-row class="input-row">
            <ion-col class="input">
                <ion-label position="stacked">Mittag & Pausen (in Std.)</ion-label>
                <ion-input formControlName="lunchAndBreaks" type="time" step="900"
                           [attr.readonly]="!isAllowedToEdit()"
                           [attr.disabled]="!isWorkday()"></ion-input>
                <div *ngIf="form.get('lunchAndBreaks').hasError('timeInvalid')" class="error-message" i18n>
                    Invalid time format, use 15m increments.
                </div>
            </ion-col>
            <ion-col class="input">
                <ion-label position="stacked">Fahrzeit</ion-label>
                <ion-input formControlName="drivingTime" type="time" step="900"
                           [attr.readonly]="!isAllowedToEdit()"
                           [attr.disabled]="!isWorkday()"></ion-input>
                <div *ngIf="form.get('drivingTime').hasError('timeInvalid')" class="error-message" i18n>
                    Invalid time format, use 15m increments.
                </div>
            </ion-col>
        </ion-row>

        <ion-row class="input-row" style="padding-top:0">
            <ion-col class="input">
                <ion-label position="stacked">Einsatzort</ion-label>
                <ion-input formControlName="location" type="text"
                           [attr.readonly]="!isAllowedToEdit()"
                           [attr.disabled]="!isWorkday()"></ion-input>
            </ion-col>
        </ion-row>

        <ion-row class="border-row">
            <ion-col class="input">
                <hr>
            </ion-col>
        </ion-row>

        <ion-row class="input-row summary-row">
            <ion-col class="input">
                <ion-label position="stacked">Arbeitszeit {{currentDateISO()}}</ion-label>
            </ion-col>
            <ion-col class="input value-col">
                <div class="value">{{workTimeTotal}}</div>
                <span class="unit">Std.</span>
            </ion-col>
        </ion-row>

        <ion-row class="input-row summary-row summary-row--last">
            <ion-col class="input">
                <ion-label position="stacked">Fahrzeit {{currentDateISO()}}</ion-label>
            </ion-col>
            <ion-col class="input value-col">
                <div class="value">{{drivingTimeTotal}}</div>
                <span class="unit">Std.</span>
            </ion-col>
        </ion-row>

        <ion-row class="submit-row">
            <ion-col class="input">
                <ion-button color="dark" expand="full" [disabled]="form.invalid" type="submit"
                            *ngIf="isAllowedToEdit()">
                    <ion-label>{{isEditMode() ? 'Einreichen' : 'Speichern'}}</ion-label>
                </ion-button>
            </ion-col>
        </ion-row>

    </form>
</ion-content>
