import {MachineModel} from '../shared-models/machine.model';
import {MaterialModel} from '../shared-models/material.model';
import UserModel from '../user/user.model';

export default class ConstructionSiteModel {
    public id: number;
    public title: string;
    public site_type: string;
    public siteNumber: string;
    public principal: UserModel;
    public projectManager: UserModel;
    public address: string;
    public contact: string;
    public jobDescription: string;
    public projectDescription: string;
    public note: string;
    public allocatedMachines: Array<MachineModel>;
    public workers: Array<UserModel>;
    public image: string;
    public googleMapUrl: string;
    public materials: Array<MaterialModel>;
    public hospital: string;
    public emergencyDoctor: string;
    public weldingPipeDimension: string;

    constructor(props) {
        this.id = props.id;
        this.title = props.title;
        this.site_type = props.site_type;
        this.siteNumber = props.site_number;
        this.principal = new UserModel(props.principal);
        this.projectManager = new UserModel(props.project_manager);
        this.address = props.address;
        this.contact = props.contact;
        this.jobDescription = props.job_description;
        this.projectDescription = props.project_description;
        this.note = props.note;
        this.allocatedMachines = props.allocated_machines.map(
            machine => new MachineModel(machine)
        );
        this.workers = props.workers.map(worker => new UserModel(worker));
        this.image = props.image;
        this.googleMapUrl = props.google_map_url;
        this.materials = props.materials.map(material => new MaterialModel(material));
        this.hospital = props.hospital;
        this.emergencyDoctor = props.emergency_doctor;
        this.weldingPipeDimension = props.welding_pipe_dimension;
    }
}
