import {UtilityService} from '../utility/utility.service';

export class MachineReportModel {
    public id: number;
    public name: string;
    public timeUsed: string;
    public machineId: number;
    public reportId: number;
    public isInactive: string;

    constructor(props) {
        this.id = props.id;
        this.name = props.machine_name;
        this.timeUsed = props.time_used;
        //this.isInactive = props.is_inactive;
        this.machineId = props.machine;
        this.reportId = props.report;
    }
}
