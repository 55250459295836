export class MaterialReportModel {
    public id: number;
    public name: string;
    public comment: string;
    public materialId: number;
    public reportId: number;
    // tslint:disable-next-line:variable-name
    public field_1_name: string;
    // tslint:disable-next-line:variable-name
    public field_1_value: number;
    // tslint:disable-next-line:variable-name
    public field_2_name: string;
    // tslint:disable-next-line:variable-name
    public field_2_value: number;
    // tslint:disable-next-line:variable-name
    public delivery_slip: any;

    constructor(props) {
        this.id = props.id;
        this.name = props.material_name;
        this.comment = props.comment;
        this.materialId = props.material;
        this.reportId = props.report;
        this.field_1_name = props.field_1_name;
        this.field_1_value = props.field_1_value;
        this.field_2_name = props.field_2_name;
        this.field_2_value = props.field_2_value;
        this.delivery_slip = props.delivery_slip;
    }
}
