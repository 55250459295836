import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {CurrentPathService} from '../../services/current-path/current-path.service';
import DrillingReportModel from '../../services/drilling-report/drilling-report.model';
import {DrillingReportService} from '../../services/drilling-report/drilling-report.service';
import {ConstructionSiteService} from '../../services/construction-site/construction-site.service';

@Component({
    selector: 'app-drilling',
    templateUrl: './drilling.page.html',
    styleUrls: ['./drilling.page.scss'],
})
export class DrillingPage implements OnInit, OnDestroy {
    public reports: Array<DrillingReportModel> = [];
    public displayChildren: boolean;
    private subscription: Subscription;
    private constructionSiteId: number;
    public isLastPipeActive = false;
    public siteType: string;

    constructor(
        private activatedRoute: ActivatedRoute,
        private reportService: DrillingReportService,
        private currentPathService: CurrentPathService,
        private constructionSiteService: ConstructionSiteService,) {
    }

    ngOnInit() {
        this.constructionSiteId = parseInt(this.activatedRoute.snapshot.parent.parent.paramMap.get('constructionSiteId'), 10);
        this.subscription = this.currentPathService.currentPathState.subscribe(value => {
            this.displayChildren = value === 'drilling';
        });
        this.getReports();
        this.siteType = this.constructionSiteService.currentSite.site_type;
    }

    getReports(scroll = false) {
        this.constructionSiteId = parseInt(this.activatedRoute.snapshot.parent.parent.parent.paramMap.get('constructionSiteId'), 10);
        this.reportService.getAll(this.constructionSiteId)
            .pipe(map(drillingReports => drillingReports.sort((report1, report2) => (report1.id > report2.id) ? 1 : -1)))
            .subscribe(reports => {
                this.reports = reports;
            });
    }

    createReport() {
        this.reportService.create(this.constructionSiteId).subscribe(reports => {
            this.getReports()
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

}
